import { ApplicationDefaults } from '@/constants/ApplicationDefaults';
import { SettingsModule } from '@/store/barrel';
import { isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { IItemUnderAnalysis } from '@/types/IItemUnderAnalysis';
import { isStrategy } from '@/utils/strategy';
import { computed } from 'vue';
import useFxConversion from './useFxConversion';
import { useBrowserLocation } from '@vueuse/core';
import useTranslation from './useTranslation';
import { hasCurrencyConversion } from '@/types/CurrencyConversion';
import { min, sumBy } from 'lodash';
/**
 * https://github.com/puppeteer/puppeteer/blob/main/docs/api.md#pagepdfoptions
 */
type PrintToPdfOptions = {
  format?: string;
  margin?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  headerTemplate?: string;
  footerTemplate?: string;
};

export function useIsPdf() {
  const location = useBrowserLocation();
  return computed(() => {
    const url = location.value.href?.toString();
    return Boolean(url?.includes('/pdf') || url?.includes('-pdf'));
  });
}

/**
 * Turn this boolean to true to test PDFs in the browser
 * If it's false and you are on local, instead it will generate
 * the PDF from UAT and return it to you.
 *
 * should be `false` in cypress environment as we need to test the download pdf feature.
 */
const shouldTestPdfInBrowser = !window.Cypress;

export function usePdfPreferences() {
  const isPdf = useIsPdf();

  const sectionHeaderTextColor = computed(() =>
    isPdf.value ? { color: `${SettingsModule.pdfSectionHeaderTextColor} !important` } : null,
  );

  const sectionDividerColor = computed(() =>
    isPdf.value ? SettingsModule.pdfSectionDividerColor : ApplicationDefaults.colors.Info,
  );

  return {
    sectionHeaderTextColor,
    sectionDividerColor,
  };
}

const generateAnalysisItemAttributes = (
  item: IItemUnderAnalysis,
  /**
   * Set this to true for pages not using the navigation panel and not saving the portfolio draft
   */
  isLegacyApiPage?: boolean,
  shouldShowFxType: boolean = true,
): string | undefined => {
  const { toCurrency, fxType } = useFxConversion();
  const { translate } = useTranslation();

  let assetClass = '';
  let factor = '';
  let currency = toCurrency.value || '';
  let fxHedging = fxType.value || '';

  const itemToUse: Partial<IItemUnderAnalysis> | undefined =
    isStrategy(item) || isPortfolioTreeSubportfolio(item) ? item : item.strategy;
  if (!itemToUse) return;

  assetClass = itemToUse.assetClass || '';
  factor = itemToUse.factor || '';

  // Allows for the pdf to use the itemUnderAnalysis' toCurrency and fxType
  // since the factor decomposition page can create a pdf on subportfolio or strategy
  // Otherwise, it will always use the root portfolio
  // Which is not applicable in PCA yet, since we are not using portfolio-trees there
  if (!isLegacyApiPage && hasCurrencyConversion(itemToUse)) {
    currency = itemToUse.toCurrency;
    fxHedging = itemToUse.fxType;
  }

  return `${translate({
    path: 'GLOBAL.ASSET_CLASS_CONSTANTS',
    item: assetClass,
  })} | ${translate({
    path: 'GLOBAL.FACTOR_CONSTANTS',
    item: factor,
  })} | ${currency} ${
    shouldShowFxType
      ? translate({
          path: 'GLOBAL.FX_CONVERSION',
          item: fxHedging,
        })
      : ''
  }`;
};

/**
 * Default pdf options to send to the sls-chromeless project
 *
 * FOR THE WHITELABEL BRANCHES, WE MAY NEED TO HAVE THIS INSTEAD:
 *  {
 *    footer: ''
 *    margin: {
 *      top: 0,
 *      bottom: 0,
 *    },
 *  }
 *
 */
export function useDefaultPdfOptions(): PrintToPdfOptions {
  return {
    margin: {
      top: 0,
      bottom: 0,
    },
  };
}

/**
 * The PDF can look very ugly with lots of factors, so we limit it to this number.
 */
const factorDecompositionPdfFactorLimit = 20;

/**
 * If the number of regressors exceeds this number, then we push the waterfall graphs to be
 * on the second page of the PDF instead of the first.
 */
const maxNumRegressorsWithWaterfallOnFrontPage = 8;

type FactorDecompPdfGraphChunk = {
  shownWithRegimes: boolean;
  shownWithoutRegimes: boolean;
  numGraphs: number;
};

const getNumGraphsForFactorDecomposition = (numRegressors: number, isRegimeEnabled: boolean): number => {
  // take the smaller of these two numbers as the number of regressor graphs drawn
  const numRegressorGraphsDrawn: FactorDecompPdfGraphChunk = {
    shownWithoutRegimes: true,
    shownWithRegimes: false,
    numGraphs: min([numRegressors, factorDecompositionPdfFactorLimit]) ?? 0,
  };

  const numWaterfallGraphsDrawn: FactorDecompPdfGraphChunk = {
    shownWithRegimes: false,
    shownWithoutRegimes: true,
    numGraphs: 2,
  };

  const otherGraphs: FactorDecompPdfGraphChunk = {
    numGraphs:
      // regression track and cumulative residual page
      2 +
      // regression return monthly and annual page
      2,
    shownWithoutRegimes: true,
    shownWithRegimes: true,
  };

  const convexityPageNoRegimes: FactorDecompPdfGraphChunk = {
    shownWithoutRegimes: true,
    shownWithRegimes: false,
    numGraphs:
      // convexity row
      1 +
      // qqplot and return distribution row
      2,
  };

  // convexity and return distribution
  const convexityPageWithRegimes: FactorDecompPdfGraphChunk = {
    shownWithRegimes: true,
    shownWithoutRegimes: false,
    numGraphs:
      // convexity row
      1 +
      // return distribution graph with different datasets
      2,
  };

  const qqPlotPageWithRegimesOnly: FactorDecompPdfGraphChunk = {
    shownWithRegimes: true,
    shownWithoutRegimes: false,
    numGraphs: 3,
  };

  const rollingPage: FactorDecompPdfGraphChunk = {
    shownWithoutRegimes: true,
    shownWithRegimes: true,
    numGraphs: 1,
  };

  return sumBy(
    [
      numRegressorGraphsDrawn,
      numWaterfallGraphsDrawn,
      otherGraphs,
      convexityPageNoRegimes,
      convexityPageWithRegimes,
      qqPlotPageWithRegimesOnly,
      rollingPage,
    ].filter(
      (p: FactorDecompPdfGraphChunk) =>
        (isRegimeEnabled && p.shownWithRegimes) || (!isRegimeEnabled && p.shownWithoutRegimes),
    ),
    'numGraphs',
  );
};

const numGraphsForPortfolioFactsheet = (
  numOfThresholds: number,
  shouldIncludeActiveReturnPage: boolean,
  isRegimeAnalysisActive: boolean,
): number => {
  const base =
    // portfolio weighting charts x2
    2 +
    // track record (always shown)
    1 +
    // graph sections x4 (with 2 charts each)
    8 +
    // allocation model because it takes a long time somehow
    1;

  if (shouldIncludeActiveReturnPage) {
    return (
      base +
      // active return track
      1 +
      // active return graph section with two charts
      2
    );
  }

  if (isRegimeAnalysisActive) {
    return (
      base +
      // n RegimeTrackRecord where n is the number of thresholds
      numOfThresholds
    );
  }

  return base;
};

export default function (): {
  shouldTestPdfInBrowser: boolean;
  generateAnalysisItemAttributes: (
    item: IItemUnderAnalysis,
    isLegacyApiPage?: boolean,
    shouldShowFxType?: boolean,
  ) => string | undefined;
  factorDecompositionPdfFactorLimit: number;
  maxNumRegressorsWithWaterfallOnFrontPage: number;
  getNumGraphsForFactorDecomposition: (numRegressors: number, isRegimeEnabled: boolean) => number;
  numGraphsForPortfolioFactsheet: (
    numOfThresholds: number,
    shouldIncludeActiveReturnPage: boolean,
    isRegimeAnalysisActive: boolean,
  ) => number;
} {
  return {
    shouldTestPdfInBrowser,
    generateAnalysisItemAttributes,
    factorDecompositionPdfFactorLimit,
    maxNumRegressorsWithWaterfallOnFrontPage,
    getNumGraphsForFactorDecomposition,
    numGraphsForPortfolioFactsheet,
  };
}
