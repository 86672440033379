#########################################################################################################################
################################################# GLOBAL TRANSLATION ####################################################
#########################################################################################################################

GLOBAL:
  DATE: 'Date'
  YEAR: 'Year'
  # This is how we handle pluralization
  # pluralIndex 1 corresponds to the first item, pluralIndex 2 corresponds to the second, etc.
  TIME_PERIOD: 'Period | Periods'

  EXPORT: 'Export'
  EXPORT_AS: 'Export As'
  DOWNLOAD: 'Download'
  CANCEL: 'Cancel'
  UPDATE: 'Update'
  APPLY: 'Apply'
  NAME: 'Name'
  PDF: 'PDF'
  EXCEL: 'Excel'
  CSV: 'CSV'
  PNG: 'PNG'
  COPY: 'Copy to Clipboard'
  JPEG: 'JPEG'
  CREATE: 'Create'
  CREATE_NEW: 'Create New'
  DELETE: 'Delete'
  RESTORE: 'Restore'
  UNDO_DELETE: 'Undo Delete'
  SAVE: 'Save'
  SAVE_AS: 'Save As'
  PUBLISH: 'Publish'
  OK: 'OK'
  RESET: 'Reset'
  RESET_TO_DEFAULT: 'Reset to default'
  CONFIRM: 'Confirm'
  SUSPEND: 'Suspend'
  UNSUSPEND: 'Undo Suspend'
  DISCARD_CHANGES: 'Discard Changes'
  DISCARD: 'Discard'
  CALCULATE: 'Calculate'
  NEW: 'New'
  OLD: 'Old'
  EDIT: 'Edit'
  SUBMIT: 'Submit'
  ADD: 'Add'
  ADD_TO: 'Add to'
  YES: 'Yes'
  NO: 'No'
  CLONE: 'Clone'
  SEARCH: 'Search'
  INVALID: 'Invalid'
  NEW_PORTFOLIO: 'New Portfolio'
  NEW_BASKET: 'New Basket'
  NEW_SUBPORTFOLIO: 'New Subportfolio'
  SUBPORTFOLIO: 'Subportfolio'
  LOADING: 'Loading'
  PORTFOLIO_INDEX: 'Portfolio Index'
  TICKER: 'Ticker'

  RELEASE: 'Release'
  REVOKE: 'Revoke'
  REMOVE: 'Remove'

  NONE: 'None'
  NO_NAME: '(no name)'

  WITH: 'with'

  PORTFOLIO_CURRENCY: 'Portfolio Currency'
  TRACK_CURRENCY: 'Track Currency'

  FX_CONVERSION:
    FX_CONVERSION: 'FX Conversion'
    PORTFOLIO_DEFAULT: 'Portfolio Default'
    NOT_CONVERTED: 'Not Converted'
    UNHEDGED: 'Unhedged'
    MONTHLY_HEDGED: 'Monthly Hedged'
    MONTHLY_HEDGED_SHORT: 'Hdg'

  CURRENCY:
    TITLE: 'Currency'
    IN: 'in {currency}'

  RETURN_INTERVAL: 'Return Interval'

  AS_OF: 'as of'
  TO: 'to'
  VS: 'vs'
  IN: 'in'

  WEIGHT: 'Weight | Weights'
  EXPOSURE: 'Exposure'
  BLM_EXPOSURE: 'BLM Exposure'

  LEVERAGE: 'Leverage'

  CHART_TITLE:
    TRACK_RECORD: 'Track Record'
    EXPOSURE: 'Exposure'
    INDEX_LEVEL: 'Index Level'

    EFFICIENT_FRONTIER: 'Efficient Frontier'
    FRONTIER_WEIGHTS: 'Frontier Weights'

    PERFORMANCE_CONTRIBUTION: 'Performance Contribution'
    RETURN_VOLATILITY: 'Return vs Volatility'
    AVERAGE_WEIGHT: 'Average Weight'
    MONTHLY_RETURN_BREAKDOWN: 'Monthly Return Breakdown'
    MONTHLY_RETURN_DISTRIBUTION: 'Monthly Return Distribution'
    RETURN_DISTRIBUTION: 'Monthly Return Distribution'
    CUMULATIVE_MONTHLY_RETURN_BREAKDOWN: 'Cumulative Monthly Return Breakdown'
    CUMULATIVE_MONTHLY_RETURN_DISTRIBUTION: 'Cumulative Monthly Return Distribution'

    ACTIVE_RETURN: 'Active Return'
    RETURN_COMPARISON: 'Return Comparison'

    RETURN_PA_ATTRIBUTION: 'Return p.a. Attribution'
    VARIANCE_PROPORTION: 'Variance Proportion'

    HEATMAP: 'Heatmap'
    REGRESSION_TRACK: 'Regression Track'
    REGRESSION_TRACK_OUT_OF_SAMPLE: 'Regression Track Out of Sample'
    CUMULATIVE_RESIDUAL: 'Cumulative Residual'
    CONVEXITY: 'Convexity'
    FACTOR_DECOMPOSITION_MONTHLY_RETURN: 'Monthly Return'
    FACTOR_DECOMPOSITION_REGRESSION_RETURN: 'Regression Return'
    FACTOR_DECOMPOSITION_ROLLING_RETURN: 'Rolling 12 Month Return'
    FACTOR_DECOMPOSITION_ROLLING_REGRESSION_RETURN: 'Rolling 12 Month Regression Return'
    QQ_PLOT: 'QQ Plot'

    ROLLING_REGRESSION: 'Rolling Regression'

    PORTFOLIO_WEIGHTING: 'Portfolio Weighting by {category}'

    CORRELATION: 'Correlation'
    DRAWDOWN: 'Drawdown'
    METRICS: 'Metrics'
    FACTSHEET_MONTHLY_RETURN_DISTRIBUTION: 'Monthly Return Distribution'
    RETURN_ATTRIBUTION: 'Return Attribution'
    RETURN_REGRESSION: 'Return Regression'
    SCALE: 'Scale'
    VAR_CONTRIBUTION: 'VaR Contribution'
    VOLATILITY_CONTRIBUTION: 'Volatility Contribution'

    ROLLING_CORRELATION: 'Rolling Correlation'
    ROLLING_SHARPE: 'Rolling Sharpe'
    ROLLING_VOLATILITY: 'Rolling Volatility'

    DAILY_PNL: 'Daily P&L'
    DAILY_PNL_TRACK: 'Daily P&L Track'
    DAILY_DRIFT: 'Daily Drift'
    CUMULATIVE_PNL: 'Cumulative P&L'
    CUMULATIVE_DRIFT: 'Cumulative Drift'

    BELL_CURVE: 'Bell Curve'

    PCA_TRACKS: 'PCA Tracks'

    ESG_RATING: 'ESG Rating'

  SORT: 'Sort'
  SORT_BY: 'Sort by'
  FILTER_BY: 'Filter by'
  ASCENDING: 'Ascending'
  DESCENDING: 'Descending'

  SORT_BY_TEAM: 'Sort by Team'
  SORT_BY_NAME: 'Sort by Name'

  # AssetClassConstants
  ASSET_CLASS_CONSTANTS:
    COMMODITY: 'Commodity'
    CREDIT: 'Credit'
    EQUITY: 'Equity'
    FIXED_INCOME: 'Fixed Income'
    FOREIGN_EXCHANGE: 'Foreign Exchange'
    REAL_ESTATE: 'Real Estate'
    PRIVATE_EQUITY: 'Private Equity'
    MULTI_ASSETS: 'Multi Assets'

  # FactorConstants
  FACTOR_CONSTANTS:
    MARKET_BETA: 'Benchmark'
    BENCHMARK: 'Benchmark'
    CARRY: 'Carry'
    VOLATILITY: 'Volatility'
    MOMENTUM: 'Momentum'
    LOW_VOL: 'Low Vol'
    QUALITY: 'Quality'
    SIZE: 'Size'
    VALUE: 'Value'
    MULTI: 'Multi'

  # FactorConstants for PortfolioSubtext and StrategySubtext
  # Multi is expanded to Multi Factor to distinguish it from Multi Assets
  FACTOR_CONSTANTS_SUBTEXT:
    CARRY: 'Carry'
    VOLATILITY: 'Volatility'
    MOMENTUM: 'Momentum'
    LOW_VOL: 'Low Vol'
    QUALITY: 'Quality'
    SIZE: 'Size'
    VALUE: 'Value'
    MULTI: 'Multi Factor'

  # RegionConstants
  REGION_CONSTANTS:
    EUROPE: 'Europe'
    APAC: 'APAC'
    EMERGING: 'Emerging'
    US: 'US'
    GLOBAL: 'Global'
    JAPAN: 'Japan'
    UK: 'UK'

  # StyleConstants
  STYLE_CONSTANTS:
    ALPHA: 'Alpha'
    BETA: 'Beta'
    SMART_BETA: 'Smart Beta'

  # ReturnTypeConstants
  RETURN_TYPE_CONSTANTS:
    RETURN: 'Return'
    ACTIVE_RETURN: 'Active Return'
    EXCESS_RETURN: 'Excess Return'
    TOTAL_RETURN: 'Total Return'
    PRICE_RETURN: 'Price Return'
    GROSS_RETURN: 'Gross Return'

  # ReturnTypeConstants for PortfolioSubtext and StrategySubtext
  RETURN_TYPE_CONSTANTS_SUBTEXT:
    EXCESS_RETURN: 'ER'
    TOTAL_RETURN: 'TR'
    PRICE_RETURN: 'PR'

  # ReturnCategoryConstants
  RETURN_CATEGORY_CONSTANTS:
    NET: 'Net'
    GROSS: 'Gross'

  # ReturnTypeConstants for StrategySubtext
  RETURN_CATEGORY_CONSTANTS_SUBTEXT:
    NET: 'N'
    GROSS: 'G'

  # RebalancingTypeConstants for tooltip
  REBALANCING_TYPE_CONSTANTS:
    CUSTOM: 'Custom'
    NO_REBALANCING: 'No Rebalancing'
    WEEKLY: 'Weekly'
    MONTHLY: 'Monthly'
    QUARTERLY: 'Quarterly'
    SEMI_ANNUAL: 'Semi-annual'
    ANNUAL: 'Annual'

  # RebalancingTypeConstants for tooltip
  REBALANCING_TYPE_CONSTANTS_SUBTEXT:
    CUSTOM: 'Custom'
    NO_REBALANCING: 'No'
    WEEKLY: 'W'
    MONTHLY: 'M'
    QUARTERLY: 'Q'
    SEMI_ANNUAL: 'SA'
    ANNUAL: 'A'

  # IntradayTypeConstants
  INTRADAY_TYPE_CONSTANTS:
    INTRADAY: 'Intraday'
    OVERNIGHT_SYSTEMATIC: 'Overnight Systematic'
    OVERNIGHT_MONTH_END: 'Overnight Month End'
    OVERNIGHT_OCCASIONAL: 'Overnight Occasional'

  WEEKDAYS:
    MONDAY: 'Monday'
    TUESDAY: 'Tuesday'
    WEDNESDAY: 'Wednesday'
    THURSDAY: 'Thursday'
    FRIDAY: 'Friday'

  LAST_DAY: 'Last Day'

  WEEKLY_INTERVAL:
    FIRST_WEEK: '1st Week'
    SECOND_WEEK: '2nd Week'
    THIRD_WEEK: '3rd Week'
    FOURTH_WEEK: '4th Week'
    LAST_WEEK: 'Last Week'

  ANALYSIS_START_DATE: 'Analysis Start Date'
  USE_ANALYSIS_START_DATE: 'Use Analysis Start Date'

  #Asset Class and Factors
  ASSET_CLASS: 'Asset Class | Asset Classes'
  COMMODITY: 'Commodity'
  CREDIT: 'Credit'
  EQUITY: 'Equity'
  FIXED_INCOME: 'Fixed Income'
  FOREIGN_EXCHANGE: 'Foreign Exchange'
  MULTI_ASSETS: 'Multi Assets'
  MULTI: 'Multi'
  FACTOR: 'Factor | Factors'
  # Note: Factor as in Strategy Factor and
  # Factor as in Factor Regression may be translated
  # differently in other languages. Use a different
  # key for Regression.

  ESG_RATING: 'ESG Rating'

  CARRY: 'Carry'
  MOMENTUM: 'Momentum'
  VALUE: 'Value'
  BENCHMARK: 'Benchmark'
  LOW_VOL: 'Low Vol'
  OTHER: 'Other | Others'
  QUALITY: 'Quality'
  SIZE: 'Size'

  STRATEGY: 'Strategy'

  UPDATED_AT: 'Updated At'
  UPDATED_BY: 'Updated By'

  DATABASE_TRANSLATOR:
    ACTIVE_RETURN: 'Active Return'
    RETURN_CUM: 'Return'
    RETURN: 'Return p.a.'
    AVERAGE: 'Average'
    VOLATILITY: 'Volatility'
    SHARPE: 'Sharpe'
    INFO_RATIO: 'Info. Ratio'
    SKEWNESS: 'Skewness'
    SKEW: 'Skewness'
    KURTOSIS: 'Kurtosis'
    CALMAR: 'Calmar'
    OMEGA: 'Omega'
    SORTINO: 'Sortino'
    VAR: 'VaR 95%'
    MVAR: 'ModVaR 95%'
    CVAR: 'CVaR 95%'
    VAR99: 'VaR 99%'
    MVAR99: 'ModVaR 99%'
    CVAR99: 'CVaR 99%'
    JARQUE_BERA_P_VALUE: 'Jarque-Bera'
    AVGDD: 'Avg DD'
    MAXDD: 'Max DD'
    UP_WEEK: '%Up Week'
    PERCENT_POS_WEEKS: '%Up Week'
    UP_MONTH: '%Up Month'
    PERCENT_POS_MONTHS: '%Up Month'
    WIN_RATE: 'Win Rate'
    WIN_LOSS_RATIO: 'Win/Loss Ratio'
    UP_HIT_RATE: 'Up Hit Rate'
    DOWN_HIT_RATE: 'Down Hit Rate'
    UPSIDE_CAPTURE: 'Upside Capture'
    DOWNSIDE_CAPTURE: 'Downside Capture'
    AUTOCORRELATION: 'Autocorrelation'
    AUTOCOR: 'Autocorrelation'
    EQUITY_BASKET: 'Equity Basket'

    P_VALUE: 'P-value'
    RETURN_ATTR: 'Return p.a. Attribution'
    VAR_PROP: 'Variance Proportion'
    FACTOR_STANDARD_ERROR: 'Standard Error Factor Fit'
    BETA_S_E: 'Standard Error'
    FACTOR_VOL: 'Factor Volatility'
    FACTOR_CORR: 'Factor Correlation'

    ASSETCLASS: 'Asset Class'
    ASSET_CLASS: 'Asset Class'
    ASSET_CATEGORY: 'Asset Category'
    LATEST_REPORT: 'Latest Report'
    WEIGHT: 'Weight'

    CODE: 'Index Code'
    CURRENCY: 'Currency'
    IN_CURRENCY: 'In Currency'
    IN_PERCENT_OF_NOTIONAL: 'In Percent of Notional'
    IN_PERCENT_OF_SELECTION: 'In Percent of Selection'
    PERCENT_VALUE: 'Percent Value'
    FACTOR: 'Factor'
    HISTORY_START_DATE: 'History Start Date'
    PROVIDER: 'Index Provider'
    INDEX_SOURCE: 'Index Source'
    INCEPTION_DATE: 'Live Date'
    LIVE_DATE: 'Live Date'
    RETURN_CATEGORY: 'Net Return'
    REGION: 'Region'
    RETURN_TYPE: 'Return Type'
    STYLE: 'Style'
    SOURCE: 'Source'
    VOL_TARGET: 'Vol Target'
    WEIGHTING: 'Weighting'
    TYPE: 'Type'
    SHORT_NAME: 'Name'
    NAME: 'Name'
    INTRADAY_TYPE: 'Intraday Type'

    AUTOMATIC_REFERENCE_IDENTIFICATION: 'Automatic Reference Identification'
    REQUESTED: 'Requested'
    REQUEST_MISSING: 'Request Missing'
    INTERNAL_REFERENCE: 'Internal Reference'
    ISIN: 'ISIN'
    TICKER: 'Ticker'
    RIC: 'RIC'
    MORNINGSTAR_ID: 'Morningstar ID'
    SEDOL: 'SEDOL'
    PRIMARY_IDENTIFIER: 'Primary Identifier'
    GLOBAL_CAT: 'Fund Category'
    BENCHMARKS: 'Benchmark(s)'
    DIVIDEND_FREQUENCY: 'Dividend'
    PROVIDER_COMPANY: 'Provider'
    UCITS: 'UCITS'
    MANAGER_NAMES: 'Manager(s)'
    DOMICILE: 'Domicile'
    PERFORMANCE_FEE: 'Performance Fee'
    LEGAL_STRUCTURE: 'Legal Structure'
    MANAGEMENT_FEE: 'Management Fee'

    THEME: 'Theme'
    ESG: 'ESG'

    PRIVATE_FUND_TYPE: 'Type'

    BLM_INFO_RATIO: 'BLM Info Ratio'
    BLM_RETURN: 'BLM Return p.a.'
    BLM_VOLATILITY: 'BLM Volatility'
    BLM_SHARPE: 'BLM Sharpe'

    COUNTRY: 'Country'
    STRATEGY: 'Strategy | Strategies'
    SECURITY: 'Security | Securities'
    PORTFOLIO: 'Portfolio | Portfolios'

    UNKNOWN: 'Unknown'
    ALL: 'All'

    GRAINS: 'Grains'
    ENERGY: 'Energy'
    SOFTS: 'Softs'
    LIVESTOCK: 'Livestock'
    INDUSTRIAL_METALS: 'Industrial Metals'
    PRECIOUS_METALS: 'Precious Metals'

    ALUMINIUM: 'Aluminium'
    BRENT_CRUDE_OIL: 'Brent Crude Oil'
    COFFEE_C: "Coffee 'C'"
    CORN: 'Corn'
    COTTON_2: 'Cotton #2'
    FEEDER_CATTLE: 'Feeder Cattle'
    GAS_OIL: 'Gas Oil'
    GOLD_100_OZ: 'Gold 100 Oz'
    HEATING_OIL: 'Heating Oil'
    HIGH_GRADE_COPPER: 'High Grade Copper'
    HRW_WHEAT: 'Hrw Wheat'
    LEAN_HOGS: 'Lean Hogs'
    LIGHT_CRUDE_OIL: 'Light Crude Oil'
    LIVE_CATTLE: 'Live Cattle'
    NATURAL_GAS: 'Natural Gas'
    NICKEL: 'Nickel'
    RBOB_GASOLINE: 'Rbob Gasoline'
    SILVER_5000_OZ: 'Silver 5000 Oz'
    SOYBEAN_MEAL: 'Soybean Meal'
    SOYBEAN_OIL: 'Soybean Oil'
    SOYBEANS: 'Soybeans'
    SUGAR_11: 'Sugar #11'
    WHEAT: 'Wheat'
    ZINC: 'Zinc'

    TECHNOLOGY: 'Technology'
    HEALTHCARE: 'Healthcare'
    INDUSTRIALS: 'Industrials'
    REAL_ESTATE: 'Real Estate'
    BASIC_MATERIALS: 'Basic Materials'
    UTILITIES: 'Utilities'
    FINANCIALS: 'Financials'
    CONSUMER_NONCYCLICALS: 'Consumer Non-Cyclicals'
    CONSUMER_CYCLICALS: 'Consumer Cyclicals'

    SOFTWARE__IT_SERVICES: 'Software & IT Services'
    INDUSTRIAL_GOODS: 'Industrial Goods'
    TECHNOLOGY_EQUIPMENT: 'Technology Equipment'
    HEALTHCARE_SERVICES__EQUIPMENT: 'Healthcare Services & Equipment'
    PHARMACEUTICALS__MEDICAL_RESEARCH: 'Pharmaceuticals & Medical Research'
    TELECOMMUNICATIONS_SERVICES: 'Telecommunications Services'
    INDUSTRIAL__COMMERCIAL_SERVICES: 'Industrial & Commercial Services'
    CHEMICALS: 'Chemicals'
    MINERAL_RESOURCES: 'Mineral Resources'
    FOOD__BEVERAGES: 'Food & Beverage'
    INSURANCE: 'Insurance'
    FINANCIAL_TECHNOLOGY_FINTECH__INFRASTRUCTURE: 'Financial Technology (Fintech) & Infrastructure'
    BANKING__INVESTMENT_SERVICES: 'Banking & Investment Services'
    CYCLICAL_CONSUMER_SERVICES: 'Cyclical Consumer Services'
    CYCLICAL_CONSUMER_PRODUCTS: 'Cyclical Consumer Products'
    APPLIED_RESOURCES: 'Applied Resources'

    SECTOR: 'Sector'
    HOLDING: 'Holding'
    AVERAGE_WEIGHT: 'Average Weight'
    PRODUCT_TYPE: 'Product Type'
    EXCHANGE: 'Exchange'
    ESG_RATING: 'ESG Rating'

    LINKED_DEPENDENCY: 'Some selected portfolios were removed as they are linked to the current portfolio'

  PERIOD_ABBREV:
    CUSTOM: 'Custom'
    10Y: '10Y'
    5Y: '5Y'
    3Y: '3Y'
    LIVE: 'Live'
    1Y: '1Y'
    6M: '6M'
    3M: '3M'
    2M: '2M'
    1M: '1M'
    MIN: 'Min'
    YTD: 'YTD'
    QTD: 'QTD'
    MTD: 'MTD'
    MAX: 'Max'

  PERIOD_FULL_NAME:
    CUSTOM: 'Custom'
    10Y: '10 Years'
    5Y: '5 Years'
    3Y: '3 Years'
    LIVE: 'Live'
    1Y: '1 Year'
    6M: '6 Months'
    3M: '3 Months'
    2M: '2 Months'
    1M: '1 Month'
    MIN: 'Minimum'
    YTD: 'Year to Date'
    QTD: 'Quarter to Date'
    MTD: 'Month to Date'
    MAX: 'Max'

  RETURN_INTERVAL_VALUE:
    DAILY: 'Daily'
    WEEKLY: 'Weekly'
    MONTHLY: 'Monthly'

  RETURN_INTERVAL_ABBREV:
    DAILY: 'D'
    WEEKLY: 'W'
    MONTHLY: 'M'

  TIME:
    DAY: 'Day | Days'
    WEEK: 'Week | Weeks'
    MONTH: 'Month | Months'
    DAILY: 'Day | Days'
    WEEKLY: 'Week | Weeks'
    MONTHLY: 'Month | Months'

  MOVING_WINDOW_VALUE:
    YEARLY: 'Yearly'
    QUARTERLY: 'Quarterly'
    MONTHLY: 'Monthly'
    NONE: 'None'

  STRATEGY_TYPE_NAME:
    PRIVATE_TRACK: 'Private Track | Private Tracks'
    BENCHMARK: 'Benchmark | Benchmarks'
    FUND: 'Fund & ETF | Funds & ETFs'
    PURE_FACTOR: 'Pure Factor | Pure Factors'
    STRATEGY: 'Strategy | Strategies'
    THEMATIC: 'Thematic | Thematics'
    PORTFOLIO: 'Portfolio | Portfolios'
    SIGNAL: 'Signal | Signals'
    MARKET_DATA: 'Market Data'
    STOCK: 'Stock | Stocks'
    EQUITY: 'Equity | Equities'
    EQUITY_BASKET: 'Equity Basket | Equity Baskets'

  WEIGHTING_TYPE_CONSTANTS:
    EQUAL: 'Equal'
    MANUAL: 'Manual Weighting'
    RISK_PARITY: 'Risk Parity'
    ERC: 'ERC Weighting'
    PC1: 'PC1 Weighting'
    CUSTOM: 'Custom Weighting'
    None: 'No Weighting'
    MINIMUM_VARIANCE: 'Minimum Variance'
    FRONTIER_VOLATILITY_TARGET: 'Volatility Target'

  # Used only for Portfolio Subtext on the Discover Page
  PORTFOLIO_TYPE_NAME:
    PORTFOLIO: 'Portfolio'
    BENCHMARK: 'Composite Benchmark'
    EQUITY_BASKET: 'Equity Basket'

  GRAPH_TYPE:
    SPIDER: 'Spider'
    LINE: 'Line'
    MST: 'MST'

  VOLATILITY_ADJUSTMENT:
    NONE: 'None'
    VOLATILITY: '%{volTarget}%'

  MAX: 'Max'
  MIN: 'Min'
  AVG: 'Avg'

  PERIOD_TOOLBAR:
    FROM_DATE: 'From Date'
    END_DATE: 'End Date'

  CONJUNCTIONS:
    AND: 'and'

  PORTFOLIO_WEIGHTING_OPTIONS:
    MINIMUM_VARIANCE_SHORT: 'Min Var'
    MINIMUM_VARIANCE_LONG: 'Minimum Variance'
    MINIMUM_VARIANCE: 'Minimum Variance'
    VOLATILITY_TARGET_SHORT: 'Vol Tgt'
    VOLATILITY_TARGET_MED: 'Volatility Target'
    VOLATILITY_TARGET_LONG: 'Frontier Volatility Target'
    VOLATILITY_TARGET: 'Volatility Target'
    NONE: 'None'
    EQUAL: 'Equal'
    MANUAL: 'Manual'
    CUSTOM: 'Custom'
    ERC: 'ERC'
    RISK_PARITY: 'Risk Parity'
    PC1: 'PC1'

  MESSAGES:
    SAVE_BEFORE_EXPORT: 'Please save your changes before exporting'

  PLACEHOLDERS:
    KEYWORD_SEARCH: 'Keyword search'

  SUBTEXT:
    ACTIVE_STRATEGIES: 'Active Strategies'
    VT: 'VT'
    CONSTRAINTS: 'Constraints'

  ERROR:
    ERROR_LOADING_DATA: 'Data currently not available'
    ERROR_MASTER_PORTFOLIO: 'Data for portfolio under analysis is not available'
    NO_ACTIVE_STRATEGIES: 'Portfolio has no active strategies'
    MISSING_TRACK: 'Referenced strategy does not have track data'
    POSITION_DATE_NOT_FOUND: 'No risk data is available for the given As Of date'

ANALYSIS_STEPS:
  TOOLBAR:
    GROUPED_BY: 'grouped by'
    TRACK: 'Track'
    BENCHMARK: 'Benchmark'
    REBALANCING: 'Rebalancing'
    RISK_FREE_RATE: 'Risk Free Rate'

  REBALANCING_TYPE:
    NO_REBALANCING: 'No Rebalancing'
    DAILY_REBALANCING: 'Daily Rebalancing'
    MONTHLY_REBALANCING: 'Monthly Rebalancing'
    QUARTERLY_REBALANCING: 'Quarterly Rebalancing'
    YEARLY_REBALANCING: 'Yearly Rebalancing'

  REBALANCING_TYPE_SUBTEXT:
    NO_REBALANCING: 'No Rebalancing'
    DAILY_REBALANCING: 'Daily'
    MONTHLY_REBALANCING: 'Monthly'
    QUARTERLY_REBALANCING: 'Quarterly'
    YEARLY_REBALANCING: 'Yearly'

  GROUP_BY:
    COMPONENT: 'Component'
    PORTFOLIO: 'Portfolio'
    STRATEGY: 'Strategy'
    ASSET_CLASS: 'Asset Class'
    FACTOR: 'Factor'
    REGION: 'Region'
    TYPE: 'Type'
    HIERARCHY: 'Hierarchy'
    CURRENCY: 'Currency'
    PROVIDER: 'Provider'
    RETURN_TYPE: 'Return Type'
    STYLE: 'Style'

  PORTFOLIO_CONSTRUCTION:
    NAME: 'Portfolio Construction'
    DESCRIPTION: 'Advanced computation engine for portfolio construction and simulations'
    BLM_CONSTANTS_NAME:
      UPPER_95_: 'Upper 95%'
      UPPER_50_: 'Upper 50%'
      MEAN_PROBABILITY: 'Mean Probability'
      LOWER_50_: 'Lower 50%'
      LOWER_95_: 'Lower 95%'
    SCENARIO:
      BTN_TITLE: 'Scenario'
      MARKET_REGIME:
        TITLE: 'Market Regime'
        NEW_NAME: 'New Regime Set'
        CREATE_REGIME: 'Create Regime Set'
        CREATE_VIEW: 'Create View'
        METHOD:
          LABEL: 'Method'
          VALUES:
            RETURN_PERCENTILE: 'Return Percentile'
            RETURN_THRESHOLD: 'Threshold Return'
            LEVEL_THRESHOLD: 'Threshold Level'
            RETURN_STANDARD_DEVIATION: 'Threshold Std Dev'
            DATE: 'Dates'
        CALIBRATION_PERIOD: 'Calibration Period'
        USE_ANALYSIS_PERIOD: 'Use analysis period'
        HEADER:
          PERIODS: 'Periods'
          DATE: 'Date'
          REGIME: 'Regime'
          NAME: 'Name'
        DEFAULT_VALUES:
          RETURN_PERCENTILE:
            THRESHOLD_1_NAME: 'Bear'
            THRESHOLD_2_NAME: 'Neutral'
            THRESHOLD_3_NAME: 'Bull'
          DATE:
            THRESHOLD_1_NAME: 'Period 1'
            THRESHOLD_2_NAME: 'Period 2'
        LABEL:
          PERIOD_START_DATE: 'Period %{index} Start Date'
          PERIOD_NAME: 'Period %{index} Name'
          PERIOD_REGIME: 'Regime for Period %{index}'
          DATE_REGIME: 'Date Regime: %{name}'
          DELETE_BUTTON: 'Delete Button'
        PLACEHOLDER:
          NEW_REGIME_NAME: 'New Regime Name'
        ERROR:
          NO_DATA: 'No regime data with the parameters specified'
          RETURN_INTERVAL: 'The analysis return interval is not compatible with the regime return interval'
          DATE_NOT_SEQUENTIAL: 'Nonsequential value'
          CREATE_REGIME_FIRST: 'Please create a Regime first'
          CREATE_AND_SELECT_REGIME_FIRST: 'Please create a Regime first and then select it here'
          DISABLED_WHEN_ACTIVE: 'This feature is only available when a market regime is not applied'
      FORWARD_LOOKING:
        TITLE: 'Forward Looking View'

  EQUITY_BASKET:
    NAME: 'Equity Basket'
    DESCRIPTION: 'Advanced computation engine for Equity Basket construction and simulations.'
    CREATE_BASKET: 'Create Basket'
    AUDIT_LOG: 'Audit Log'
    DOWNLOAD_AUDIT: 'Download Audit'
    MODIFIED_BY: 'Modified By'
    EVENT: 'Event'
    STATUS: 'Status'
    CHANGE_LOG: 'Change Log'
    DATE_TIME: 'Date & Time'
    AS_OF_DATE: 'As Of Date'
    CREATION_TYPE: 'Creation Type'
    RETURN_TYPE: 'Return Type'
    BASKET_CURRENCY: 'Basket Currency'
    BASKET_NOTIONAL: 'Basket Notional'
    DIVIDEND_TREATMENT: 'Dividend Treatment'
    BASKET_TICKER: 'Basket Ticker'
    BASKET_STATUS:
      DRAFT: 'Draft'
    CASH_REINVESTMENT_TYPE:
      DISTRIBUTE: 'Distribute as Cash'
      REINVEST_AT_RFR: 'Reinvest in Risk-Free Rate'
      REINVEST_AT_OPEN: 'Reinvest in Dividend Stocks'

  CLUSTERING_ANALYSIS_LEGACY:
    NAME: 'Principal Component Analysis'
    DESCRIPTION: 'Gain insights into portfolio diversification and strategy variance structures'

  FACTOR_DECOMPOSITION_LEGACY:
    NAME: 'Factor Decomposition'
    DESCRIPTION: 'Analyse portfolio factor exposure and intensity with the Pure Factor Toolbox'

  CONSTITUENT_RISK:
    NAME: 'Constituent Risk'
    DESCRIPTION: 'Ex-ante risk, stress tests and sensitivities with look-through on portfolio constituents'

  EFFICIENT_FRONTIER: 'Efficient Frontier'
  FRONTIER_WEIGHTS: 'Frontier Weights'

MODALS:
  GENERAL:
    DELETE_PROMPT: 'You are about to permanently delete {0}.'
    DELETE_PROMPT_CHILL: 'You are about to delete {0}.'
    SELECT_BENCHMARK: 'Select Benchmark'
    SELECT_PORTFOLIO: 'Select Portfolio'
    FROM: 'from'
    SELECT_ALL: 'Select All'
    SELECT_VISIBLE: 'Select Visible'
    TOASTS:
      CREATE:
        SUCCESS: 'The item has been successfully created'
        FAIL: 'Operation failed. The item was not created.'
      UPDATE:
        SUCCESS: 'The item has been successfully updated'
        FAIL: 'Operation failed. The item was not updated.'
      DELETE:
        SUCCESS: 'The item has been successfully deleted'
        FAIL: 'Operation failed. The item was not deleted.'

  ANALYSIS_TOOL_MODAL:
    ANALYSIS_TOOL: 'Analysis Tool'
    SELECT: 'Select'
    SELECTED: 'Selected'
    BUTTONS:
      ANALYSE_NOW: 'Analyse Now'
  EQUITY_SEARCH_BOX_MODAL:
    TITLE: 'Add equities to %{portfolio}'
  INDEX_SEARCH_BOX_MODAL:
    TITLE: 'Add strategies to %{portfolio}'
  PROXY_TABLE_LINK_MODAL:
    TITLE: 'Select proxy for %{trackName}'
    PLEASE_MAKE_SELECTION: 'Please make a selection'

  PROXY_EDIT_ATTRIBUTE_MODAL:
    EDIT_TRACK_ATTRIBUTES: 'Edit Track Attributes'
    PROPERTIES:
      NAME: 'Name'
      INTERNAL_IDENTIFIER: 'Internal Identifier'
      DESCRIPTION: 'Description'
      MANAGEMENT_FEE: 'Management Fee'

  PROXY_TABLE_TRACK_MODAL:
    SUBSTITUTION_TRACK: 'Substitution Track'

  BLACK_LITTERMAN:
    TITLE: 'Black-Litterman'
    VIEWS: 'Views'
    CREATE_VIEW: 'Create View'
    EDIT_VIEW: 'Edit View'
    CALIBRATION_PERIOD: 'Calibration Period'
    FORWARD_WINDOW: '%{window} Forward Window'
    EXPECTED: 'Expected'
    CONFIDENCE_LEVEL: 'Confidence Level'
    STATUS_MESSAGES:
      ROW_DISABLED: 'Views with a confidence level of 0 will not be included in the analysis'
      CONFIDENCE_LEVEL: 'You must have at least one view with a confidence level above 0'
      EMPTY_VIEW: 'Please either delete this view or add a view'

  STATIC_DATA_MODAL:
    PORTFOLIO_TITLE: 'Portfolio Attributes'
    TRACK_TITLE: 'Track Attributes'
    PORTFOLIO_NAME: 'Portfolio Name'
    SUCCEED_DUPLICATE_TOAST: 'Portfolio copied successfully. Redirecting to the new portfolio.'
    FAILED_DUPLICATE_TOAST: 'Failed to copy portfolio.'
    PORTFOLIO_SUCCEED_DELETE_TOAST: 'Portfolio has been deleted.'
    STRATEGY_SUCCEED_DELETE_TOAST: 'Strategy has been deleted.'
    PORTFOLIO_FAILED_DELETE_TOAST: 'Failed to delete portfolio.'
    STRATEGY_FAILED_DELETE_TOAST: 'Failed to delete strategy.'
    BASKET_SUCCESS_SUBMIT_TOAST: 'Basket has been submitted successfully.'
    CREATED_BY: 'Created: {when} by {who}'
    UPDATED_BY: 'Updated: {when} by {who}'
    INHERITED: 'Inherited'
    ENTER_SOMETHING: 'Enter Something…'
    EXPORT_TYPE:
      TREE: 'Tree'
      PRIVATE_TRACK: 'Private Track'
    SELECT_DATE: 'Select Date'
    TRACK_NOT_FOUND: 'Track cannot be found. Please contact {email} if you need any assistance.'
    TICKER_INPUT: 'Please enter your ticker'

  CREATE_PORTFOLIO:
    TITLE: 'Create Portfolio'
    CREATE: 'Create'

  REBALANCE_BASKET:
    TITLE: 'Rebalance Basket'

  CREATE_BASKET:
    TITLE: 'Create Basket'
    COLUMNS:
      CODE: 'Code'
      NAME: 'Name'
      CURRENCY: 'Currency'
      COMPLIANT: 'Compliant'
      OLD_WEIGHT: 'Old Weight'
      TARGET_WEIGHT: 'Target Weight'
      WEIGHT_CHANGE: 'Weight Change'

  DUPLICATE_PORTFOLIO:
    TITLE: 'Save as…'

  EDIT_WEIGHTING:
    LEVERAGE_PARAMETERS: 'Leverage Parameters'
    WEIGHTING_OPTIONS: 'Weighting Options'
    WEIGHTING_TYPE: 'Weighting Type'
    EQUAL: 'Equal'
    MANUAL: 'Manual'
    ERC: 'ERC'
    RISK_PARITY: 'Risk Parity'
    RISK: 'Risk Parity'
    MINIMUM_VARIANCE: 'Minimum Variance'
    TYPE: 'Type'
    SCALE: 'Scale'
    VOL_TARGET_INTERVAL: 'Vol Target Interval'
    RISK_METRIC: 'Risk Metric'
    METRIC_INTERVAL: 'Metric Interval'
    MATRIX_TYPE: 'Matrix Type'
    MATRIX_INTERVAL: 'Matrix Interval'
    VARIANCE_INTERVAL: 'Variance Interval'
    REBALANCING_COST: 'Rebalancing Cost'
    REPLICATION_COST: 'Replication Cost'
    ALLOCATION_CONSTRAINTS: 'Allocation Constraints'
    PRIMARY: 'Primary'
    SECONDARY: 'Secondary'
    CUSTOM_MODEL: 'Custom Model'
    SELECT_ONE: 'Select one…'
  PORTFOLIO_GROUP_BY:
    GROUPING_LAYERS: 'Grouping Layers'
    LAYER: 'Layer'
    REMOVE_HIERARCHY: 'Please remove the hierarchy grouping layer first'
    ADD_GROUPING: 'Add Grouping Layer'
    APPLY_WARNING: 'Please be aware that the grouping functionality will overwrite your current weighting parameters.'
    PORTFOLIO_GROUPING_CONSTANTS:
      HIERARCHY: 'Hierarchy'
      ASSET_CLASS: 'Asset Class'
      CURRENCY: 'Currency'
      FACTOR: 'Factor'
      PROVIDER: 'Provider'
      REGION: 'Region'
      RETURN_TYPE: 'Return Type'
      STYLE: 'Style'
      TYPE: 'Type'

TOASTS:
  POPUPS_BLOCKED: 'It appears that popups are being blocked by your browser. Please enable popups for %{domain} and try to download your file again.'
  PREMIUM_FEATURE: 'This functionality is for premium users only. Please contact your account manager for details.'
  PDF_EXPORT_FAILED: 'Failed to generate PDF'
  EXCEL_EXPORT_FAILED: 'Failed to generate Excel'
  CSV_EXPORT_FAILED: 'Failed to generate CSV'
  COPIED_TO_CLIPBOARD: 'Copied to clipboard'
  FACTOR_DECOMPOSITION:
    EMPTY_PORTFOLIO: 'You may not regress against an empty portfolio'
    EMPTY_REGRESSION: 'Please add some active factors in order to proceed with the factor decomposition analysis'
    CHANGES_APPLIED: 'Changes applied'
    NO_CHANGES_APPLIED: 'No changes have been made'
  ADMIN_CONSOLE:
    GET_OPTIONS_FAILURE: 'Failed to load providers and teams list'
    GET_STRATEGY_FAILURE: 'Failed to get strategy for %{code}'
    UPDATE_TRACK_SUCCESS: 'Updated the track %{code} successfully'
    UPDATE_TRACK_FAILURE: 'Failed to update track %{code} - %{message}'
    CREATE_TRACK_SUCCESS: 'Created the track %{code} successfully'
    CREATE_TRACK_CONFLICT: 'Failed to create track for %{code} - requested strategy exists'
    CREATE_TRACK_FAILURE: 'Failed to create track for %{code} - %{message}'
    SOFT_DELETE_FAILURE: 'Failed to soft delete for %{code}'
    DOWNLOAD_TRACK_FAILURE: 'Failed to download track for %{code}'
    ARTICLE_SAVE_SUCCESS: 'Article Changes Successful'
    ARTICLE_SAVE_FAILURE: 'Article Changes Unsuccessful. Please try again'
    MISSING_ARTICLE_TITLE: 'Please enter a unique title for the article'
    INVALID_STRATEGY_TICKER: 'Invalid strategy ticker for the article'
    ARTICLE_CREATE_SUCCESS: 'Created new article. Redirecting to the edit page'
    ARTICLE_CREATE_FAILURE: 'Failed to create article'
    # API Keys
    GET_API_KEYS_FAILURE: 'Failed to get API keys'
    COPY_API_KEY_TO_CLIPBOARD_SUCCESS: 'Copied API Key to clipboard'
    COPY_API_SECRET_TO_CLIPBOARD_SUCCESS: 'Copied API Secret to clipboard'
    # Client Request
    GET_CLIENT_REQUEST_LIST_FAILURE: 'Failed to get client request data for %{code}'
    GET_CLIENT_REQUEST_DETAIL_FAILURE: 'Failed to get client request detail for %{code}'
    GET_CONSTITUENT_STATUS_FAILURE: 'Failed to get the constituent status for %{code}'
    UPDATE_CONSTITUENT_STATUS_SUCCESS: 'Updated the constituent status for %{code} successfully'
    UPDATE_CONSTITUENT_STATUS_FAILURE: 'Failed to update the constituent status for %{code} - %{message}'
    CLIENT_REQUEST_ADD_STRATEGIES_SUCCESS: 'Added strategies %{code} to client request %{jiraTicket}'
    CLIENT_REQUEST_ADD_STRATEGIES_FAILURE: 'Fail to add strategies %{code} to client request %{jiraTicket}'
    CLIENT_REQUEST_DETAIL_UPDATE_SUCCESS: 'Updated client request detail for %{code}'
    CLIENT_REQUEST_DETAIL_UPDATE_FAILURE: 'Failed to update client request detail for %{code} - %{message}'
    CLIENT_REQUEST_UPDATE_SUCCESS: 'Updated client request %{jiraTicket}'
    CLIENT_REQUEST_UPDATE_FAILURE: 'Failed to update client request %{jiraTicket}'
    CLIENT_REQUEST_DELETE_SUCCESS: 'Deleted client request %{jiraTicket}'
    CLIENT_REQUEST_DELETE_FAILURE: 'Failed to delete client request %{jiraTicket} - %{message}'
    CREATE_CLIENT_REQUEST_SUCCESS: 'Created client request %{jiraTicket}'
    CREATE_CLIENT_REQUEST_FAILURE: 'Failed to create client request %{jiraTicket} - %{message}'
    BATCH_UPDATE_PROVIDER_NAME_SUCCESS: 'Updated provider name in batch successfully'
    BATCH_UPDATE_PROVIDER_NAME_FAILURE: 'Failed to update provider name in batch - %{message}'
    BATCH_UPDATE_RELEASE_STATUS_SUCCESS: 'Updated release status in batch successfully'
    BATCH_UPDATE_RELEASE_STATUS_FAILURE: 'Failed to update release status in batch - %{message}'
    BATCH_UPDATE_CONSTITUENT_STATUS_SUCCESS: 'Updated constituent status in batch successfully'
    BATCH_UPDATE_CONSTITUENT_STATUS_FAILURE: 'Failed to update constituent status in batch - %{message}'
    BATCH_UPDATE_VALIDATION_STATUS_SUCCESS: 'Updated validation status in batch successfully'
    BATCH_UPDATE_VALIDATION_STATUS_FAILURE: 'Failed to update validation status in batch - %{message}'
    STRATEGY_LIST_UPDATE_SUCCESS: 'Updated strategy list'
    STRATEGY_LIST_UPDATE_FAILURE: 'Failed to update strategy list'
    PORTFOLIO_RELEASE_SUCCESS: 'Released portfolio successfully'
    PORTFOLIO_RELEASE_FAILURE: 'Failed to release portfolio'
    PORTFOLIO_REMOVE_ACCESS_SUCCESS: 'Removed portfolio access successfully'
    PORTFOLIO_REMOVE_ACCESS_FAILURE: 'Failed to remove portfolio access'
    GET_CLIENT_REQUEST_FAILURE: 'Failed to get all client requests'
    GET_CLIENT_REQUEST_PORTFOLIO_DETAIL_FAILURE: 'Failed to get client request portfolio details'
    BASKET_ACTION:
      APPROVE:
        SUCCESS: 'Basket %{slug} approved successfully'
        FAILURE: 'Failed to approve basket %{slug}'
      REJECT:
        SUCCESS: 'Basket %{slug} rejected successfully'
        FAILURE: 'Failed to reject basket %{slug}'
      ACK:
        SUCCESS: 'Basket %{slug} acknowledged successfully'
        FAILURE: 'Failed to acknowledge basket %{slug}'

  NAVIGATION_PANEL:
    PORTFOLIO:
      SAVED:
        SUCCESS: 'Portfolio saved successfully'
        FAIL: 'There was a problem saving your portfolio'
      EXISTING_TRACK_UNPINNED: 'This track already exists within the currently active portfolio. Pinning the track on that portfolio instead.'
      EXISTING_TRACK_PINNED: 'This track already exists within the currently active portfolio and is pinned.'
      NEGATIVE_TRACK_VALUES_MESSAGE: 'Error - the options you have entered resulted in negative track values. Please adjust your options and try again.'
      INVALID_SIM_INPUT: 'Please enter a valid number'
    DRAG_N_DROP:
      STRATEGY_ALREADY_EXISTS: 'You may not place this strategy in this portfolio. This strategy already exists in this portfolio.'
    TRIANGULATION:
      LOCKING:
        CANNOT_LOCK_BOTH_NOTIONAL_AND_UNIT: 'Cannot lock both basket notional and unit'
        CANNOT_LOCK_BOTH_WEIGHT_AND_UNIT: 'Cannot lock both basket weight and unit'
  BLM:
    UNIQUE_VIEW_NAME_ERROR: 'Please choose a unique name for this BLM view.'
    CREATED:
      SUCCESS: 'BLM view created successfully'
      ERROR: 'There was an error creating this view'
    UPDATED:
      SUCCESS: 'BLM view updated successfully'
      ERROR: 'There was an error updating this view'
    DELETED:
      SUCCESS: 'BLM view deleted successfully'
      ERROR: 'There was an error deleting this view'
  USER_SETTING:
    TEAM_DEFAULT_PARAMETERS:
      UPDATE_SUCCESS: 'Updated team default parameters'
      UPDATE_FAILURE: 'Failed to update team default parameters'

ROUTES:
  MY_LAB:
    MY_PORTFOLIOS: 'My Portfolios'
    DROP_ZONE: 'Drop Zone'
    PROXY_TABLE: 'Proxy Table'
    SWAPS: 'Swaps'
    LISTED_PRODUCTS: 'Listed Products'
    TRADE_REPOSITORY: 'Trade Repository'
    MORNINGSTAR: 'Morningstar'
    TITLE: 'My Lab'

NAVIGATION_PANEL:
  ITEM_UNDER_ANALYSIS: 'Item under Analysis'
  RISK_PORTFOLIO: 'Risk Portfolio'
  SNAPSHOT_PORTFOLIO: 'Snapshot Portfolio'
  BACKTEST_PORTFOLIO: 'Backtest Portfolio'
  START_FRESH_PORTFOLIO: 'Start Fresh Portfolio'
  SIMULATION_PORTFOLIO: 'Simulation Portfolio'
  NO_SAVE_ON_PAST_SNAPSHOT: 'A past snapshot cannot be saved and replace the current portfolio'
  NO_SAVE_ON_PORTFOLIO_GROUP_BY_RISK: 'A risk portfolio cannot have its structure updated'
  PORTFOLIO_SHARING:
    TEAM: 'Team'
    PORTFOLIO_URL: 'Portfolio URL'
    PORTFOLIO_ACCESS: 'Portfolio Access (%{num})'
  COLUMNS:
    WEIGHT: 'Weight'
    UNITS: 'Units'
    PRICE: 'Price'
    CURRENCY: 'Currency'
    CODE: 'Code'
    SECTOR: 'Sector'
    REGION: 'Region'
    FRONTIER: 'Frontier'
    COMPLIANT: 'Compliant'
    EXPOSURE: 'Exposure'
    TICKER: 'Ticker'
  ROW:
    SETTINGS:
      ATTRIBUTES: 'Attributes'
      REBALANCING_OPTIONS: 'Rebalancing Options'
      FACTSHEET: 'Factsheet'
  ADD_DROPDOWN:
    FUNCTIONALITY_NOT_AVAILABLE: 'This functionality is not available on Snapshot, Backtest, or Constituent Risk portfolios'
  WORKFLOW:
    SUBMIT_BASKET: 'Submit Basket'
    CHECK_COMPLIANCE: 'Check Compliance'
    BASKET_ADJUSTMENT: 'Basket Adjustment'
  STATUS: 'Status'
  BASKET_STATUS:
    COMPLIANT: 'Compliant'
    DRAFT: 'Draft'
    REJECTED: 'Rejected'
    LIVE: 'Live'
    ARCHIVED: 'Archived'
    NOT_COMPLIANT: 'Not Compliant'
    PENDING: 'Pending'
  NOTIONAL: 'Notional'
  CASH: 'Cash'
  TOOLTIPS:
    LOCK_UNIT: 'Lock Unit'
    UNLOCK_UNIT: 'Unlock Unit'
    LOCK_WEIGHT: 'Lock Weight'
    UNLOCK_WEIGHT: 'Unlock Weight'

#########################################################################################################################
################################################# FACTSHEET TRANSLATION #################################################
#########################################################################################################################

FACTSHEETS:
  GO_TO_FACTSHEET: 'Open Factsheet on a new page'

  PORTFOLIO_FACTSHEET: 'Portfolio Factsheet'

  SCALE_TOOLTIP_TEXT: 'Scale: {value}'

  PERFORMANCE_SUMMARY:
    TITLE: 'Performance Summary'
    DISPLAYED_METRICS: 'Displayed Metrics'
    BENCHMARK_UNAVAILABLE: 'Benchmark unavailable'
    ACTIVE_RETURN_UNAVAILABLE: 'Active Return unavailable'

  # Not only used in factsheets. Currently also used in factor decomposition of strategy
  NOT_FOUND:
    TITLE1: 'Cannot find the specified strategy'
    TITLE2: 'You may not have permission to view this strategy. Please contact your administrator.'
    BACK_BUTTON: 'Back to home'

  ERROR:
    PORTFOLIO_NOT_FOUND:
      TITLE: 'Cannot find the specified portfolio'
      SUBTITLE: 'The portfolio may be deleted or you may not have permission to view this portfolio.'

    PORTFOLIO_NETWORK_ERROR:
      TITLE: 'Could not load the specified portfolio'
      SUBTITLE: 'Please check the network connection and/or try again later.'

    PORTFOLIO_LOAD_FAIL:
      TITLE: 'Could not load the specified portfolio'
      SUBTITLE: 'Please try again later.'

  ERROR_BUTTONS:
    BACK_TO_HOME: 'Back to home'
    RETRY: 'Retry'

  TOASTS:
    FAILED_TO_LOAD_TRACK: 'Failed to load track'

  FACTSHEET_TOOLBAR:
    ADD_TO_PORTFOLIO: 'Add to Portfolio'
    TIME: 'Time'
    PERIOD: 'Period'
    INDEX_VALUE: 'Index Value'

  STRATEGY_ATTRIBUTES:
    BENCHMARKS:
      NONE: 'None'
      REMOVE_SELECTED: 'Remove Selected'
      MULTIPLE: 'Multiple'
      SELECT: 'Select a benchmark'
    SAVE:
      SUCCEEDED: 'Custom strategy data saved successfully'
      FAILED: 'Failed to save custom strategy data'

  MONTHLY_RETURN_TABLE:
    OF:
      FUND: 'Fund'
      BENCHMARK: 'Benchmark'
    MONTHLY_RETURN_%: 'Monthly Return (%)'
    MISSING_DATA: 'Missing data'

  MONTHLY_RETURN_DISTRIBUTION:
    NUMBER_OF_MONTHS: 'Number of Months'
    MONTHLY_VARIATION: 'Monthly Variation'
    DENSITY: 'Density'
    REGRESSION_TRACK: 'Regression Track'
    PORTFOLIO: 'Portfolio'
    STRATEGY: 'Strategy'
    BENCHMARK: 'Benchmark'
    REGRESSION: 'Regression'
    RETURN: 'Return'
    ACTIVE_RETURN: 'Active Return'
    SCALE: 'Scale'
    DATASET: 'Dataset'

  PORTFOLIO_COMPOSITION_SECTION:
    COMPOSITION: 'Composition'
    DISPLAYED_CATEGORIES: 'Displayed categories'

  RISK_METRICS_TABLE:
    TITLE: 'Risk Metrics'
    PERFORMANCE_METRICS: 'Performance Metrics'

  TOP_10_HOLDINGS: 'Top 10 Holdings'

  ALLOCATION_MODEL:
    TITLE: 'Allocation Model'
    METRICS_INTERVAL: 'Metrics Interval'
    REBALANCING_INTERVAL: 'Rebalancing Interval'
    PRIMARY_CONSTRAINT: 'Primary Constraint'
    SECONDARY_CONSTRAINT: 'Secondary Constraint'
    SCALE: 'Scale'
    VOLATILITY_WINDOW: 'Volatility Window'
    MODEL: 'Model'
    REBALANCING: 'Rebalancing'
    REBALANCING_MONTH: 'Rebalancing Month'
    REBALANCING_DATE: 'Rebalancing Date'
    VOLATILITY_INTERVAL: 'Volatility Interval'
    ERC_INTERVAL: 'ERC Interval'
    TARGET_INTERVAL: 'Target Interval'
    MIN_LEVERAGE: 'Min Leverage'
    MAX_LEVERAGE: 'Max Leverage'
    PRIMARY_ALLOCATION_CONSTRAINT: 'Primary Allocation Constraint'
    MIN: 'Min'
    MAX: 'Max'
    NONE: 'None'
    EVERY_MONTH: 'Every Month'
    SECONDARY_ALLOCATION_CONSTRAINT: 'Secondary Allocation Constraint'
    FIXED_LEVERAGE: 'Fixed Leverage'
    TARGET_VOLATILITY: 'Target Volatility'
    TARGET_EXPOSURE: 'Target Exposure'
    TARGET_LONG_EXPOSURE: 'Target Long Exposure'
    OBSERVATION_LAG: 'Observation Lag'

  SMALL_GRAPH:
    CORRELATION_IS_NOT_AVAILABLE_FOR_SINGLE_ITEMS: 'Correlation is not available for single items'
    TYPE:
      CORRELATION: 'Correlation'
      DRAWDOWN: 'Drawdown'
      EXPOSURE: 'Exposure'
      MONTHLY_RETURN_DISTRIBUTION: 'Monthly Return Distribution'
      RETURN_ATTRIBUTION: 'Return Attribution'
      RETURN_REGRESSION: 'Return Regression'
      CORREL_INTERVAL: 'Rolling Correlation'
      METRICS: 'Metrics'
      SHARPE_INTERVAL: 'Rolling Sharpe'
      VOLATILITY_INTERVAL: 'Rolling Volatility'
      SCALE: 'Scale'
      VAR_CONTRIBUTION: 'VaR Contribution'
      VOLATILITY_CONTRIBUTION: 'Volatility Contribution'
      MINIMUM_SPANNING_TREE_MST: 'Minimum Spanning Tree (MST)'

  STRATEGY_FACTSHEET:
    TITLE: 'Strategy Factsheet'
    LINK: 'Link'

  STRATEGY_FACTSHEET_PDF:
    LINK: 'Link'
    START_DATE: 'Start Date'
    END_DATE: 'End Date'
    RETURN_INTERVAL: 'Return Interval'
    MARKET_REGIME: 'Market Regime'

  GRAPH_SECTION:
    OVERVIEW: 'Overview'
    ROLLING: 'Rolling'
    ALLOCATION__LEVERAGE: 'Allocation & Leverage'
    CORRELATION: 'Correlation'
    CONTRIBUTION: 'Contribution'
    CUSTOM_SECTION: 'Custom Section'
    REGRESSION_FIT: 'Regression Fit'
    ROLLING_INTERVAL: 'Rolling Interval'
    ACTIVE_RETURN: 'Active Return'

    REGRESSION_FIT_NAME:
      LINEAR: 'Linear'
      QUADRATIC: 'Quadratic'
      POLY_3: 'Poly(3)'
    Y_AXIS: 'Y Axis'

    EXPOSURE_MODE:
      LONG__SHORT: 'Long / Short'
      GROSS: 'Gross'
      NET: 'Net'

    MONTHLY_RETURN_DISTRIBUTION_MODE:
      LINEAR_SCALE: 'Linear Scale'
      LOGARITHMIC_MODE: 'Logarithmic Scale'

    ROLLING_INTERVAL_VALUE:
      1M: '1M'
      3M: '3M'
      6M: '6M'
      1Y: '1Y'
      2Y: '2Y'
      3Y: '3Y'

    EXPONENTIAL: 'Exp %{num}'
    EXPONENTIAL_MOVING_AVERAGE: 'Exponential'

  CUSTOM_PROPERTIES:
    TITLE: 'Custom Properties'
    ADD: 'Add Property'
    CREATE: 'Create Property'
    SAVE:
      SUCCEEDED: 'Changes to custom properties saved successfully'
      FAILED: 'Failed to save changes to custom properties'
    MODAL:
      ADD_NEW_POSSIBLE_VALUES:
        TITLE: 'Add Possible Values'
        PLACEHOLDER: 'Enter or paste a list of possible values here. You can separate each value by commas, or enter one per line.'
      MANAGE_EXISTING_POSSIBLE_VALUES: 'Manage Possible Values'
      TOASTS:
        CREATE_PROPERTY:
          SUCCEEDED: 'Created %{property} successfully'
          FAILED: 'Failed to create %{property}'
        UPDATE_PROPERTY:
          SUCCEEDED: 'Updated %{property} successfully'
          FAILED: 'Failed to update %{property}'
        DELETE_PROPERTY:
          SUCCEEDED: 'Deleted %{property} successfully'
          FAILED: 'Failed to delete %{property}'
      FEEDBACK:
        VALUE_EXISTS: 'Value already exists'
      DELETE_PROPERTY_WARNING: 'You are about to permanently delete {0}. Please be aware the custom property will be removed for ALL strategies which use it.'
      DELETE_VALUE_WARNING: 'You have deleted some values. Deleting a value will remove this property for all strategies that use this value.'
      SAVE_DISABLED: 'In order to save a custom property, it must have a name and at least one possible value'

PERFORMANCE_ATTRIBUTION:
  TITLE: '{pageTypeDropdown} of {strategyName} by {groupFilterDropdown} {PerformanceContributionType}'
  RECONCILIATION_TITLE: '{pageTypeDropdown} of {strategyName}'
  PERFORMANCE_ATTRIBUTION: 'Performance Contribution'
  PERFORMANCE_ATTRIBUTION_BY: 'Performance Contribution by %{groupFilter}'
  PERFORMANCE_CONTRIBUTION: 'Performance Contribution'
  RETURN_VS_VOLATILITY: 'Return vs Volatility'
  ACTIVE_RETURN_VS_VOLATILITY: 'Active Return vs Volatility'

  # duplicated (with long versions) in section below
  TABLE_COL_TITLES:
    PORTFOLIO: 'Portfolio'
    STRATEGY: 'Strategy'
    ASSET_CLASS: 'Asset Class'
    UNDERLYING: 'Underlying'
    COMPONENT: 'Component'
    FACTOR: 'Factor'
    REGION: 'Region'
    TYPE: 'Type'
    SECTOR: 'Sector'
    COUNTRY: 'Country'
    CURRENCY: 'Currency'
    ASSET_CATEGORY: 'Asset Class'
    AVERAGE_WEIGHT: 'Avg Weight'
    AVERAGE_GROSS_WEIGHT: 'Avg Gr Wgt'
    EFFECTIVE_RETURN: 'Eff. Return'
    CUMULATIVE_RETURN: 'Return'
    TOTAL_CONTRIBUTION: 'Contribution'
    WEIGHT: 'Weight'

  # duplicated (with short versions) in section above
  PORTFOLIO: 'Portfolio'
  STRATEGY: 'Strategy'
  ASSET_CLASS: 'Asset Class'
  UNDERLYING: 'Underlying'
  COMPONENT: 'Component'
  FACTOR: 'Factor'
  REGION: 'Region'
  TYPE: 'Type'
  SECTOR: 'Sector'
  COUNTRY: 'Country'
  CURRENCY: 'Currency'
  ASSET_CATEGORY: 'Asset Class'
  AVERAGE_WEIGHT: 'Average Weight'
  AVERAGE_GROSS_WEIGHT: 'Average Gross Weight'
  EFFECTIVE_RETURN: 'Effective Return'
  CUMULATIVE_RETURN: 'Return'
  TOTAL_CONTRIBUTION: 'Contribution'
  WEIGHT: 'Weight'

  RECONCILIATION: 'Reconciliation'
  PARAMETERS:
    TITLE: 'Parameters'
    INCLUDE_UNEXPLAINED_CASH_AND_FEES: 'Include Unexplained in Cash & Fees'
    INCLUDE_INTRADAY_PNL: 'Include Intraday Performance'
    PERFORMANCE_CONTRIBUTION_TYPE: 'Performance Contribution Type'
    HAS_INTRADAY_TRADES: 'Has Intraday Trades'
    MINIMUM_VALID_DATE: 'Minimum Valid Date'
  ACTIVE_RETURN: 'Active Return'
  TOP: 'Top'
  BOTTOM: 'Bottom'
  TOP_10_TOTAL: 'Top %{numConstituents} Total'
  BOTTOM_10_TOTAL: 'Bottom %{numConstituents} Total'
  RETURN_DAILY_CHART_TITLE: '%{groupFilter} Effective Return'
  DAILY_PNL: 'Daily P&L'
  WEEKLY_PNL: 'Weekly P&L'
  MONTHLY_PNL: 'Monthly P&L'
  DAILY_PNL_LINE: 'Daily P&L Track'
  CUMULATIVE_PNL: 'Cumulative P&L'
  PNL_DAILY_DRIFT: 'Daily Drift'
  PNL_CUMULATIVE_DRIFT: 'Cumulative Drift'
  CENTER_LABEL: 'Total %{groupFilter}'
  AVERAGE_WEIGHT_CHART_TITLE: '%{groupFilter} Net Weight'
  AVERAGE_GROSS_WEIGHT_CHART_TITLE: '%{groupFilter} Gross Weight'
  RETURN_BREAKDOWN_MONTHLY: 'Monthly Return Breakdown'
  CUMULATIVE_RETURN_BREAKDOWN_MONTHLY: 'Cumulative Monthly Return Breakdown'
  RETURN_BREAKDOWN_YEARLY: 'Yearly Return Breakdown'
  CUMULATIVE_RETURN_BREAKDOWN_YEARLY: 'Cumulative Yearly Return Breakdown'
  RETURN_BREAKDOWN_QUARTERLY: 'Quarterly Return Breakdown'
  CUMULATIVE_RETURN_BREAKDOWN_QUARTERLY: 'Cumulative Quarterly Return Breakdown'
  LONG_SHORT: 'Long/Short'
  DIRECTIONAL: 'Directional'
  TOP_BOTTOM_10: 'Top/Bottom {numConstituents} by {groupFilter}'
  RETURN_HISTORY: 'Effective Return History'
  WEIGHT_HISTORY: 'Weight History'
  PERFORMANCE_VS_VOLATILITY: 'Performance vs Volatility'
  VOLATILITY: 'Volatility'
  CUMULATIVE_CONTRIBUTION_TO_RETURN: 'Cumulative Contribution to Return'
  MONTHLY_PERFORMANCE_REPORT: 'Monthly Performance Report'
  ERROR_LOADING_DATA: 'Data currently not available'
  BANK: 'Bank'
  IPV: 'IPV'
  TAYLOR: 'Taylor'
  SUBSTEP:
    CONTRIBUTION: 'Performance Contribution'
    RECONCILIATION: 'Reconciliation'
  TOOLTIPS:
    DAILY_NAV: 'Daily NAV'
    DAILY_PNL: 'Daily P&L'
    WEEKLY_NAV: 'Weekly NAV'
    WEEKLY_PNL: 'Weekly P&L'
    MONTHLY_NAV: 'Monthly NAV'
    MONTHLY_PNL: 'Monthly P&L'
    RETURN_DIFFERENCE: 'Return Difference'
    PNL_CUMULATIVE_RETURN: 'P&L Cumulative Return'
    NAV_CUMULATIVE_RETURN: 'NAV Cumulative Return'
    PNL_CUMULATIVE_DRIFT: 'P&L Cumulative Drift'
    PNL_DAILY_DRIFT: 'P&L Daily Drift'
  ERRORS:
    NO_DATA: 'No Data Available'
    NO_DATA_SUBTEXT: 'You either do not have access to this strategy or there is no data available for this strategy. Please contact us for more information.'

CONSTITUENT_RISK:
  NOT_ENOUGH_DATA: 'This portfolio does not have enough data'
  NO_SUCH_SENSITIVITY: "This set of strategies doesn't show such sensitivity"
  OVERVIEW_NOT_AVAILABLE: 'Overview is not available when there is only one item'
  AS_OF_DATE: 'As Of Date'
  REFERENCE_POINT: 'Reference Point'
  OPTIONS: 'Options'
  PARAMETERS: 'Parameters'
  INVALID_BASIS_POINT: 'Please enter a valid positive/negative number'
  HISTORY: 'History'
  POSITION: 'Position'
  RIBBON: 'Ribbon'
  UNIT: 'Unit'
  PORTFOLIO_NOTIONAL: 'Portfolio Notional'
  SENSITIVITIES: 'Sensitivities'
  COMMODITY_TREE_TITLE: "by\xa0\xa0Sector Level 1\xa0/\xa0Commodity"
  COMMODITY_BAR_LINE_TITLE: "Net\xa0\xa0by\xa0\xa0Contract\xa0Month\xa0\xa0/"
  INTEREST_RATE_MAIN_TITLE: "by\xa0\xa0Tenor\xa0\xa0/\xa0\xa0Currency of Risk"
  INTEREST_RATE_CHILD_1_TITLE: "by\xa0\xa0Tenor\xa0\xa0/"
  CREDIT_MAIN_TITLE: "by\xa0\xa0Tenor\xa0\xa0/\xa0\xa0Country"
  CREDIT_CHILD_1_TITLE: "by\xa0\xa0Tenor\xa0\xa0/"
  FILTER: 'Filter'
  OVERVIEW: 'Overview'
  ANALYSE: 'Analyse'
  GROUP_BY: 'Group By'
  CONVEXITY: 'Convexity'
  BY: 'by'
  BY_STRATEGY: "by\xa0\xa0Strategy"
  YIELD: 'Yield'
  COLUMN_SELECTOR: 'Select Columns'
  RESET_WEIGHTS: 'Restore Initial Weights'
  FORWARD_PRICE: 'Forward Price'
  ADD_NEW: 'Add New'
  DISABLE_LEVERAGE_PARAMS: 'Leverage Parameters are not editable for Constituent Portfolios'
  CUSTOM_STRESS: 'Custom Stress'
  LADDERS:
    SPOT: 'Spot Ladder'
    SPOT_VOL: 'Spot / Volatility Ladder'
    VOL_SPOT: 'Volatility / Spot Ladder'
    VOL: 'Volatility Ladder'
  STRESS_TEST_TITLES:
    HISTORICAL: 'Historical Stress Test'
    TRANSITIVE: 'Transitive Stress Test'
    CUSTOM: 'Combined Transitive Stress Test'
  STRESS_TEST_ITEMS:
    EVENT: 'Event'
    BENCHMARK: 'Benchmark'
    EQUITY: 'Equity'
    FIXED_INCOME: 'Fixed Income'
    CREDIT: 'Credit'
    FX: 'FX'
    COMMODITY: 'Commodity'
    VOLATILITY: 'Volatility'

  TABS:
    VA_R: 'VaR'
    STRESS_TEST: 'Stress Test'
    EQUITY: 'Equity'
    INTEREST_RATE: 'Interest Rate'
    CREDIT: 'Credit'
    FX: 'FX'
    COMMODITY: 'Commodity'

  VAR_FILTER_TYPES:
    ASSET_CLASS: 'Asset Class'
    COUNTRY: 'Country'
    CURRENCY: 'Currency'
    FACTOR: 'Factor'
    STRATEGY: 'Strategy'
    SECTOR: 'Sector'
    PORTFOLIO: 'Portfolio'
    PROVIDER: 'Provider'
    PRODUCT_TYPE: 'Product Type'

  EXPOSURE:
    LONG: 'Long'
    SHORT: 'Short'
    NET: 'Net'
    GROSS: 'Gross'
    TOTAL: 'Total'

  METRIC:
    CONVEXITY: 'Convexity'
    CONVEXITY_NORM: 'Convexity (Norm Vol)'
    CONVEXITY_BS: 'Convexity (B&S Vol)'
    DELTA: 'Delta'
    GAMMA: 'Gamma'
    THETA: 'Theta'
    VEGA: 'Vega'

  EQUITY:
    SECTOR_LEVEL_1: 'Sector Level 1'
    SECTOR_LEVEL_2: 'Sector Level 2'
    COUNTRY: 'Country'
    CURRENCY: 'Currency'

  VAR:
    EXPOSURE_NOTIONAL: 'Exposure Notional'
    NOTIONAL: 'Notional'
    ALLOCATED: 'Allocated'
    STANDALONE: 'Standalone'

  STRESS_TEST:
    TRANSITIVE_STRESS_TEST: 'Transitive Stress Test'
    HISTORICAL_STRESS_TEST: 'Historical Stress Test'

  TABLE:
    TABS:
      ASSET: 'Asset'
      VAR: 'VaR'
      EXPOSURE: 'Exposure'
      TRANSITIVE_STRESS: 'Transitive Stress'
      HISTORICAL_STRESS: 'Historical Stress'
      SENSITIVITY: 'Sensitivity'
      DV01_BY_TENOR: 'DV01/Tenor'
      CS01_BY_TENOR: 'CS01/Tenor'
      FX_DELTA: 'FX Delta'
      DELTA_BY_MONTH: 'Delta/Month'
      GAMMA_BY_MONTH: 'Gamma/Month'
      LADDER: 'Ladder'
      SPOT_VOL_LADDER: 'Spot/Vol Ladder'
      SPOT_VOL_NORMAL_LADDER: 'Spot/Vol Ladder'
      SPOT_VOL_BS_LADDER: 'Spot/Vol Ladder'
      CUSTOM: 'Custom'

      DROPDOWN_ITEMS:
        DV01_BY_TENOR: 'DV01 (%{basisPoint}) by Tenor'
        SENSITIVITY: 'Sensitivity'
        CS01_BY_TENOR: 'CS01 (%{basisPoint}) by Tenor'
        DELTA_BY_MONTH: 'Delta By Month'
        GAMMA_BY_MONTH: 'Gamma By Month'
        FX_DELTA: 'FX Delta'
        LADDER: 'Ladder'
        SPOT_VOL_LADDER: 'Spot/Vol Ladder'
        SPOT_VOL_NORMAL_LADDER: 'Spot/Vol Ladder (Normal Vol)'
        SPOT_VOL_BS_LADDER: 'Spot/Vol Ladder (B&S Vol)'

    LAYER_CONSTANTS:
      ASSET_CLASS: 'Asset Class'
      COUNTRY: 'Country'
      CURRENCY: 'Currency'
      CURRENCY_OF_RISK: 'Currency of Risk'
      FACTOR: 'Factor'
      INSTRUMENT: 'Instrument'
      ISSUER: 'Issuer'
      ISSUER_TYPE: 'Issuer Type'
      LONG_SHORT: 'Long/Short'
      MATURITY: 'Maturity'
      PORTFOLIO: 'Portfolio'
      PRODUCT_TYPE: 'Product Type'
      PROVIDER: 'Provider'
      REGION: 'Region'
      SECTOR: 'Sector'
      UNDERLYING: 'Underlying'
      COMMODITY: 'Commodity'
      STRATEGY: 'Strategy'
      VOLATILITY_SHOCK: 'Volatility Shock'

  PDF:
    AS_OF: 'As Of'
    SUMMARY: 'Summary'
    EXPOSURE_NOTIONAL_GROSS: 'Exposure Notional Gross'
    EXPOSURE_NOTIONAL_NET: 'Exposure Notional Net'
    VALUE: 'Value'

KNOWLEDGE_BASE:
  KNOWLEDGE_BASE: 'Knowledge Base'
  HOW_CAN_WE_HELP: 'How can we help?'
  SELECT_A_CATEGORY: 'Select a category'
  POPULAR_ARTICLES: 'Popular articles'
  LEARN_MORE: 'Learn more'
  CATEGORIES:
    GETTING_STARTED: 'Getting Started'
    PURE_FACTORS: 'Pure Factors'
    WHITEPAPER: 'Whitepaper'
    MY_LAB: 'My Lab'
    PORTFOLIO_CONSTRUCTION: 'Portfolio Construction'
    PRINCIPAL_COMPONENT: 'Principal Component'
    FACTOR_DECOMPOSITION: 'Factor Decomposition'
    CONSTITUENT_RISK: 'Constituent Risk'
    FACTSHEET: 'Factsheet'
    CASE_STUDY: 'Case Study'
    API_DEVELOPER: 'API Developer'
    API_DOCUMENTATION: 'API Documentation'
    GLOSSARY: 'Glossary'
    NOTION: 'Notion'

DATA:
  CREATE_PORTFOLIO: 'Create Portfolio'
  CREATE_BASKET: 'Create Basket'
  DATA_UNIVERSE: 'Data Universe'
  Y_AXIS: 'Y Axis'
  X_AXIS: 'X Axis'
  DISPLAYING_NUM_TRACKS: 'Displaying %{num} Track | Displaying %{num} Tracks'
  PINNED_NUM_TRACKS: 'Pinned %{num} Track | Pinned %{num} Tracks'
  FILTER_TRACKS: 'Start typing to filter tracks…'
  COMPARE_PINNED: 'Compare Pinned'
  SHOW_UNIVERSE: 'Show Universe'
  CREATE_A_NEW_PORTFOLIO: 'Create a New Portfolio'
  PINNED_TRACKS: 'Pinned Strategies'
  TOP_TRACKS: 'Top %{num} Strategies'
  TRACK_NAME: 'Track Name'
  FACTOR: 'Factor'
  INCLUDE: 'Include'
  COLUMN_SELECTION: 'Column Selection'
  METRIC: 'Metric'
  SHOW_TRACKS: 'Show Tracks'
  CLEAR_PINNED: 'Clear Pinned'
  PIN_TOP: 'Pin Top %{num}'
  GRAPH_TYPE: 'Graph Type'
  VOLATILITY_ADJUSTMENT: 'Volatility Adjustment'
  COLOR: 'Color'
  COLOR_BY:
    ASSET_CLASS: 'Asset Class'
    FACTOR: 'Factor'
    PINNED: 'Pinned'
    LABELS: 'Labels'
  MODALS:
    ADD_STRATEGY_TO_PORTFOLIO:
      ADD_STRATEGY: 'Add Strategy'
      ADD_SPECIFIC_STRATEGY: 'Add %{strategyName}'
      ADD_TO_EXISTING_PORTFOLIO: 'Add to Existing Portfolio'
      ADD_TO_NEW_PORTFOLIO: 'Add to New Portfolio'
      SEARCH_FOR_PORTFOLIO: 'Search for a portfolio by name'
      STATUS:
        FULL: 'Full'
        ADDED: 'Added'
      BUTTONS:
        CREATE: 'Create'
        VIEW: 'View'
  UNIVERSE_CHARTS:
    RISK_RETURN: 'Risk Return'
    QUANT_SPACE: 'Quant Space'
    UNIVERSE: 'Universe'
    ALPHA: 'Alpha'
    SMART_BETA: 'Smart Beta'
  UNIVERSE_COLORS:
    COLOR: 'Color'
    ASSET_CLASS: 'Asset Class'
    TYPE: 'Product Type'
    STYLE: 'Style'
    FACTOR: 'Factor'
  TOASTS:
    CREATE_PORTFOLIO:
      SUCCESS: 'Portfolio created successfully. Click to view.'
      ERROR: 'There was an error creating the portfolio.'
    ADD_STRATEGY_TO_PORTFOLIO:
      SUCCESS: 'Strategy successfully added to the portfolio. Click to view.'
      ERROR:
        FORBIDDEN: 'You are not the owner of this portfolio so you may not add a strategy to it.'
        GENERIC: 'There was an error adding the strategy to the portfolio.'
    ADD_SUBPORTFOLIO_TO_PORTFOLIO:
      SUCCESS: 'Subportfolio successfully added to the portfolio. Click to view.'
      ERROR:
        FORBIDDEN: 'You are not the owner of this portfolio so you may not add a subportfolio to it.'
        GENERIC: 'There was an error adding the subportfolio to the portfolio.'
  VALIDATION:
    PORTFOLIO_TITLE_MISSING: 'Please supply a title for the portfolio.'
    MISMATCHED_QUOTES: 'Please ensure your query has matching quotes'

  DISCOVER_COLUMN_HEADER:
    INVALID_YEAR: 'Your year input is invalid. Please verify this input before applying changes.'

VIRTUAL_LIST:
  NO_RESULTS: 'No results'

PCA:
  SETTINGS:
    OPTIONS: 'Options'
    FILTER_SETTING: 'Filter Setting'
    COV_TYPE:
      TITLE: 'Cov Type'
      COVARIANCE: 'Covariance'
      CORRELATION: 'Correlation'
    THRESHOLD:
      NONE: 'なし'
      POSITIVE_RETURNS: 'Positive Return'
      NEGATIVE_RETURNS: 'Negative Return'
      STD_DEV_POSITIVE_1: '+ 1 Std.Dev'
      STD_DEV_POSITIVE_2: '+ 2 Std.Dev'
      STD_DEV_NEGATIVE_1: '- 1 Std.Dev'
      STD_DEV_NEGATIVE_2: '- 2 Std.Dev'
      STD_DEV_POSITIVE_NEGATIVE_1: '+- 1 Std.Dev'
      STD_DEV_POSITIVE_NEGATIVE_2: '+- 2 Std.Dev'
    NO_OF_OBS: 'No of obs'

  TOASTS:
    SAVE:
      SUCCESS: 'Saved successfully'
  NO_DATA_AVAILABLE: 'No data available'
  ERRORS:
    TRACK_NOT_COMPUTABLE: '%{track} is not computable'

FACTOR_DECOMPOSITION:
  SUBSTEPS:
    FACTOR_DECOMPOSITION: 'Factor Decomposition'
    ROLLING: 'Rolling'
    CONVEXITY: 'Convexity'
  TEMPLATE: 'Template | Templates'
  REGRESSION: 'Regression'
  REGRESSION_ON: 'Regression on'
  EDIT_REGRESSION: 'Edit Regression'
  CONVEXITY:
    REGRESSION_RETURN: 'Regression Return'
    PORTFOLIO_RETURN: 'Portfolio Return'
    ACTIVE_RETURN: 'Active Return'
    RESIDUALS: 'Residuals'
    RETURN: 'Return'
  QQ_PLOT:
    SAMPLE_QUANTITIES: 'Sample Quantities'
    RESIDUAL: 'Residual'
    RETURN: 'Return'
    REGRESSION_RETURN: 'Regression Return'
    THEORETICAL_QUANTITIES: 'Theoretical Quantities'
    RESIDUAL_QUANTITIES: 'Residual Quantities'
    EXCESS_RETURN: 'Excess Return Quantities'
    TOTAL_RETURN: 'Return Quantities'
    ACTIVE_RETURN: 'Active Return Quantities'
  OUT_OF_SAMPLE: 'Out of Sample'
  RETURN: 'Return'
  REGRESSION_RETURN: 'Regression Return'
  MOVING_AVERAGE: 'Moving Average'
  STANDARD_DEVIATION_POSITIVE: 'Standard Dev + 1'
  STANDARD_DEVIATION_NEGATIVE: 'Standard Dev - 1'
  FILTER_NUMBER:
    MISSING: 'Please enter a number of factors for the selection methodology'
    INVALID: 'Please enter a number above zero'
  SAVE_AS:
    LABEL:
      PORTFOLIO_SET: 'Portfolio Set'
      STRATEGY_SET: 'Strategy Set'
    ERROR:
      MISSING_NAME: 'Please enter a name for the regression'
      UNIQUE_NAME: 'Please enter a unique name for this regression'
  DROPDOWNS:
    ROLLING_REGRESSION:
      RETURN_INTERVAL_ON: 'Return Interval on'
      ROLLING: 'Rolling'
      WINDOW: 'Window'
      CUSTOM: 'Custom'
      PRESET: 'Preset'
    SAVE_AS_PRIVATE_TRACK: 'Save as Private Track'
    VOL_SCALING: 'Vol Scaling'
  REGRESSION_CONTROLS:
    CREATE_TEMPLATE: 'Create Template'
    SAVE_AS_NEW: 'Save As New'
    IMPORT_EXISTING: 'Import Existing'
    PORTFOLIO_FACTOR_SET: 'Portfolio Factor Set'
    STRATEGY_FACTOR_SET: 'Strategy Factor Set'
    ADD_FACTORS: 'Add Factors'
    LASSO: 'LASSO'
    LASSO_LARS: 'LASSO LARS BIC'
    ORGANISE: 'Organise'
    ORGANISE_SELECTED: 'Organise Selected'
    ORGANISE_SUSPENDED: 'Organise Suspended'
    SAVE_BEFORE_EDITING_ANOTHER: 'Please save your changes before making changes to another regression'
  MODALS:
    EDIT_REGRESSION:
      EDIT_FACTOR_SET: 'Edit Factor Set'
      EDIT_TEMPLATE: 'Edit Template'
      ADD_REGRESSORS: 'Add Regressors'
      SELECTED_REGRESSORS: 'Selected Regressors'
    IMPORT_FACTORS:
      REGRESSION_TYPE: 'Regression Type'
      NUM_REGRESSORS: 'Number of Regressors'
      REMOVE_FILTER: 'Remove Filter'
    PARAMETERS:
      TITLE: 'Parameters'
      METHODOLOGY:
        TITLE: 'Methodology'
        MULTIVARIATE: 'Multivariate OLS'
        EXPONENTIAL: 'Exponentially Weighted OLS'
      RETURN_INTERVAL: 'Return Interval'

  PORTFOLIO_SETS: 'Portfolio Sets'
  STRATEGY_SETS: 'Strategy Sets'
  ANALYSIS_DATE:
    UPDATED: 'Updating period because start date is after current period start date'
    MISSING:
      STRATEGY: 'Unable to set period, strategy is missing for portfolio tree item'
      PORTFOLIO_START_DATE: 'Unable to set period, history start date for subportfolio is missing'
  REGRESSION_TABLE:
    ADD_FACTORS_TO_START: 'Add factors to start'
    DELETE_REGRESSION: 'Delete regression'
    COL_HEADERS:
      BETA_COEFFICIENT: 'Beta Coefficient'
      BETA: 'Beta'
      P_VALUE: 'P-value'
      RETURN_PA_ATTRIBUTION: 'Return p.a. Attribution'
      RETURN_PA_PROPORTION: 'Return p.a. Proportion'
      VARIANCE_ATTRIBUTION: 'Variance Attribution'
      VARIANCE_PROPORTION: 'Variance Proportion'
      STANDARD_ERROR: 'Standard Error'
      T_STAT: 'T-stat'
      LOWER_95: 'Lower 95%'
      UPPER_95: 'Upper 95%'
      FACTOR_STANDARD_ERROR: 'Standard Error Factor Fit'
      BETA_S_E: 'Standard Error'
      FACTOR_VOL: 'Factor Volatility'
      FACTOR_CORR: 'Factor Correlation'
    RESIDUAL: 'Residual'
    ROLLING_RESIDUAL: 'Rolling Residual'
    TOGGLE_PIN_FOR: 'Toggle pin for'
    TOGGLE_SELECTION_OF: 'Toggle selection of'
  CHART_OPTIONS:
    FACTOR_DECOMPOSITION_HEATMAP: 'Heatmap'
    FACTOR_DECOMPOSITION_REGRESSION_TRACK: 'Regression Track'
    FACTOR_DECOMPOSITION_CUMULATIVE_RESIDUAL: 'Cumulative Residual'
    FACTOR_DECOMPOSITION_CONVEXITY: 'Convexity'
    FACTOR_DECOMPOSITION_MONTHLY_RETURN: 'Monthly Return'
    FACTOR_DECOMPOSITION_REGRESSION_RETURN: 'Regression Return'
    FACTOR_DECOMPOSITION_ROLLING_RETURN: 'Rolling 12 Month Return'
    FACTOR_DECOMPOSITION_REGRESSION_ROLLING_RETURN: 'Regression Rolling Return'
    FACTOR_DECOMPOSITION_RETURN_DISTRIBUTION_SECTION: 'Regression Return Distribution'
    FACTOR_DECOMPOSITION_MONTHLY_RETURN_DISTRIBUTION: 'Monthly Return Distribution'
  CHART_ADD_ONS:
    IN_SAMPLE: 'In Sample'
    OUT_OF_SAMPLE: 'Out of Sample'
    RETURN: 'Return'
    REGRESSION_RETURN: 'Regression Return'
    MONTHLY: 'Monthly'
    QUARTERLY: 'Quarterly'
    ANNUAL: 'Annual'
    MONTHLY_RETURN: 'Monthly Return'
    ANNUAL_RETURN: 'Annual Return'
  HEATMAP:
    RISK_FACTORS: 'Risk Factors'
  ROLLING_REGRESSION:
    AXIS_TITLES:
      ROLLING_BETA: 'Rolling Beta'
      ROLLING_RETURN_ATTRIBUTION: 'Rolling Return Attribution'
      ROLLING_VARIANCE_PROPORTION: 'Rolling Variance Proportion'
    BETA: 'Beta'
    RETURN_ATTRIBUTION: 'Return Attribution'
  SAVED:
    CHANGES: 'Changes saved'
    MANUAL_SUCCESS: 'Successfully saved regression'
    FAIL: 'There was a problem saving the regression'
  IMPORT:
    SUCCESS: 'Successfully imported factor sets'
    FAIL: 'There was a problem importing the factor sets'
  DELETED:
    SUCCESS: 'Successfully deleted the regression'
    FAIL: 'There was a problem deleting the regression'
  SAVE_RESIDUAL_TRACK:
    SUCCESS: 'Successfully saved a new private track. Please visit the My Lab page to view it, or click here to access its factsheet'
    FAIL: 'There was a problem saving the new private track'
  ERROR:
    CALCULATION: 'Failed to calculate regression'
    OUT_OF_SAMPLE: 'Regression end date must be in the past to display out of sample data'
    PERIOD_TOO_SHORT: 'This regression cannot be computed with the current amount of regressors (%{numRegressors}). Please reduce the number of regressors to %{targetNumRegressors} or select a longer analysis period.'
    INVALID_ROLLING_PERIOD: 'The analysis period you have selected is not valid to perform a rolling regression'
    REGRESSION:
      NOT_FOUND: 'The regression with the id specified was not found. Redirecting to a usable regression.'
      NO_FACTORS: 'Please add some factors to enable this analysis'
      NO_ACTIVE_FACTORS: 'Please add some active factors to enable this analysis'
      SAVE_NO_FACTORS: 'You may not save a regression with no factors'
    EXCEL:
      MISSING_PARAMS: 'Exporting the Excel document failed because of invalid parameters'
  REGRESSION_PDF:
    FACTOR_REGRESSION_TITLE: 'Factor Decomposition'
    ROLLING_REGRESSION: 'Rolling Regression'
  MODEL_SELECTOR:
    MODEL: 'Model'
    SELECT_MODEL: 'Select Model'
    REGRESSORS: 'Regressors'
    NUM_REGRESSORS: '# Regressors'
    RESIDUAL_RETURN_P_A: 'Residual Return p.a.'
    NEW_SET:
      PORTFOLIO: 'New Portfolio Set'
      STRATEGY: 'New Strategy Set'
    NEW_TEMPLATE: 'New Template'
    CREATE_FACTOR_SET: 'Create Factor Set'
  TABLE: 'Table'
  GRAPH: 'Graph'
  FACTOR_SELECTION: 'Factor Selection'
  RESIDUAL_TRACK: 'Residual Track'
  CUMULATIVE_RESIDUAL: 'Cumulative Residual'
  BOLLINGER_BANDS_1Y_1SD: 'Bollinger Bands 1Y 1SD'

PROXY_TABLE:
  PROXIED_BY: 'proxied by'

ERROR:
  DATA_DISCOVERY_MST_FAILED: 'Failed to calculate MST'
  DATA_DISCOVERY_LINE_FAILED: 'Failed to generate line chart'
  DATA_DISCOVERY_LINE_FAILED_DESC: 'The following tracks were not found: %{tracks}'
  DATA_DISCOVERY_MST_MIN_STRATEGIES: 'Please pin at least 2 tracks to calculate MST'
  DATA_DISCOVERY_LINE_MIN_STRATEGIES: 'Please pin at least 1 track to generate line chart'
  PERIOD_TOO_SHORT: 'Please select a longer analysis period or a shorter return interval'
  START_DATE_NOT_VALID_FOR_ALL_INDICES: 'The start date is not valid for all indices. The maximum start date is'
  TIME_PERIOD_INVALID: 'The current track has an invalid period. Please review its details in My Lab and ensure that it has a proper Live Date and History Start Date.'
  TIME_PERIOD_SHORTENED: 'The current time period is not valid for the selected indices; it has been shortened to match the period for which all indices have data.'
  NO_ACTIVE_STRATEGIES: 'Please note that the portfolio you have chosen has no active tracks. No data may be calculated with zero active tracks.'
  DISABLED_BEFORE_DATE_UNDEFINED: 'The history start date for this item is undefined'
  FETCH_PORTFOLIO_TREE: 'Error getting portfolio tree'
  PCA_ERROR: 'Failed to retrieve the results. No valid data point with the filter setting in given time period. Please try another setting.'
  MISSING_TRACK: 'Tracks are not available for the selected indices.'
  BLM_NO_TRACK_DATA: 'The selected track does not have data covering the current portfolio analysis date'
  MISSING_DATA: 'There is no data available for this position. Please select a different position date or portfolio.'
  MISSING_PORTFOLIO: 'There is no data available for this portfolio. Please select a different portfolio.'
  FETCH_CONSTITUENT_DATA: 'Failed to fetch constituent data.'
  HEATMAP_FAILED: 'Failed to get heatmap'
  HEATMAP_UNAVAILABLE: 'The heatmap cannot be generated for periods before 2008-07-01'
  PCA_SAVE_FILTER_FAILED: 'Error occurred in saving the filter values'
  PCA_PDF_DOWNLOAD_FAILED: 'Error occurred in downloading the PDF document'
  PAGE_NOT_FOUND:
    TITLE: '404 Page Not Found'
    SUBTEXT: 'Please check that the URL you entered is correct, or click the button below to return to the homepage.'

SAVE_CHANGES_PROMPT:
  TITLE:
    NEW: 'Save or Discard Changes'
    LEAVING_PAGE: 'You are leaving the page'
    UNSAVED_CHANGES: 'You have unsaved changes'
    ACTION: 'You must save or discard your changes before performing that action'
    ACTION_NEW: 'You must decide what to do with your changes before performing that action.'
  SUBTITLE:
    WRAPPER: 'Do you want to save your {changeType} changes?'
    PORTFOLIO_CHANGES: 'portfolio'
    ANALYSIS_CHANGES: 'analysis'
    ARTICLE_CHANGES: 'article'
    PORTFOLIO_GROUP_BY: 'Previous portfolio structure will be lost. Do you want to save your portfolio changes?'
  SUCCESS:
    DEFAULT: 'Changes saved successfully'
  ERROR:
    DEFAULT: 'There was an error saving changes'
    SAVE_DISABLED: 'But you may not save until you correct the errors on the page'

CONFIRMATION_DIALOGS:
  ARE_YOU_SURE: 'Are you sure you want to do this? This cannot be undone.'
  DELETE_CONFIRMATION: 'Are you sure you want to delete this item?'
  REMOVE_PORTFOLIO_ACCESS_CONFIRMATION: 'Are you sure you want to remove access to this portfolio?'
  RESTORE_CONFIRMATION: 'Are you sure you want to restore this item?'
  SAVE_REGRESSION_CONFIRMATION: 'Are you sure you want to save a change to this template? This will affect the template across all pages.'
  PUBLISH_PORTFOLIO_CONFIRMATION: 'You are about to publish the portfolio'
  PORTFOLIO_PRIVATE_STRATEGIES_DESC: 'Any private strategies will automatically be shared to the selected team(s).'
  COMPLETE_SUBMITTING_THE_BASKET: 'Complete submitting the basket ?'
  PLEASE_MAKE_SURE_ALL_CHANGES_ARE_FINALIZED: 'Please make sure all changes are finalized. If no further changes, you may proceed with submitting the basket'

ARTICLES:
  ADD_NEW_ARTICLE: 'Add New Article'
  EDIT_ORDER: 'Edit Order'
  PUBLISH: 'Publish'
  UNPUBLISH: 'Unpublish'
  ORDER: 'Order'
  TITLE: 'Title'
  STATUS: 'Status'
  PUBLISHER: 'Publisher'
  PUBLISHED_AT: 'Publish Date'
  UPDATED_AT: 'Updated At'
  CATEGORY: 'Category'
  READ_HERE: 'Read Here'
  APPEND_BLOCK: 'Add Content Block'
  EDITOR:
    TITLE: 'Edit article'
    BACK: 'Return to article list'
    TRANSLATE: 'Change editing language'
    VIEW_BANNER: 'Preview as Banner'
    VIEW_CARD: 'Preview as Card'
  META_EDIT:
    UNTRANSLATED: 'Using English text. Edit the text in the input box to add a translation'
    TRANSLATED: 'Translated text. Original text is: "{0}". Click to reset translation'
    ARTICLE_TITLE:
      LABEL: 'Title'
      PLACEHOLDER: 'Enter Article Title, please ensure it is unique'
    PREVIEW_IMAGE:
      LABEL: 'Preview Image Link'
      PLACEHOLDER: 'Enter Article Preview Image Link'
      DESCRIPTION: 'Dimensions: 320 (W) x 250 (H)'
    PREVIEW_DESCRIPTION:
      LABEL: 'Preview Description'
      PLACEHOLDER: 'Enter the description of the article'
    ARTICLE_CATEGORY:
      LABEL: 'Category'
      PLACEHOLDER: 'Enter the category of the article'
    PUBLISHED_DATE:
      LABEL: 'Published Date'
      PLACEHOLDER: 'Enter the article publish date'
    TSE_CODE:
      LABEL: 'Ticker'
      PLACEHOLDER: 'Enter the ticker that the article is linked to'
    PRIMARY_COLOR:
      LABEL: 'Primary Color'
    SECONDARY_COLOR:
      LABEL: 'Secondary Color'
  BANNER:
    EDIT_BANNER: 'Edit banner image'
    FACTSHEET: 'Factsheet'
    BANNER: 'Banner'
  BANNER_EDIT:
    MODAL_TITLE: 'Edit Banner'
    URL:
      TITLE: 'Use an external source as banner image'
      OPTION_TEXT:
        # Keyed by **value** of BannerImageType enum
        IMAGE: 'Image'
        YOUTUBE: 'YouTube (video)'
        VIMEO: 'Vimeo (video)'
      LABEL:
        IMAGE: 'Banner Image Link'
        YOUTUBE: 'Video ID can be found in <{example}>'
        VIMEO: 'Video ID can be found in <{example}>'
      PLACEHOLDER:
        IMAGE: 'Banner Image Link'
        YOUTUBE: 'Paste YouTube video ID here…'
        VIMEO: 'Paste Vimeo video ID here…'
    OR: 'or'
    UPLOAD:
      LABEL: 'Choose the media file you want to upload as a banner'
      PLACEHOLDER: 'Choose a file or drop it here'
      FILE_CONSTRAINT: 'Accepts any image. Max file size 10MB'
      DROP_PLACEHOLDER: 'Drop file here'
      LOADING: 'Uploading…'
      BUTTON: 'Upload'
      SUCCESS_TOAST: 'Uploaded image. Press save to apply the change'
      FAILED_TOAST: 'Failed to upload image'
  BLOCKS:
    DRAG: 'Drag-and-drop to reorder blocks'
    EDIT: 'Edit this block'
    DELETE: 'Delete this block'
    TEXT:
      LABEL: 'Rich Text'
      MODAL_TITLE: 'Edit Rich Text'
    LINE_CHART:
      LABEL: 'Line Chart'
      MODAL_TITLE: 'Edit Line Chart'
      ERROR_MESSAGE: 'Please select at least one Index'
      MULTI_LINE_MESSAGE: 'Allow user to chart: '
      IS_RAW_TRACK_MESSAGE: 'Type of track chart: '
      DEFAULT_TIME_PERIOD: 'Default Period: '
      MIN_Y_VALUE: 'Min Y-Axis Value: '
      MAX_Y_VALUE: 'Max Y-Axis Value: '
      SINGLE_LINE: 'Single Line'
      MULTIPLE_LINES: 'Multiple Lines'
      RAW_VALUES: 'Raw Values'
      START_AT_100: 'Start at 100'
    WORDCLOUD:
      LABEL: 'Word Cloud'
      MODAL_TITLE: 'Edit Word Cloud'
      TICKER:
        LABEL: 'Source'
      MAX_COUNT:
        LABEL: 'Maximum number of words to be displayed'
        UNLIMITED: 'Unlimited'
    TABLE:
      LABEL: 'Table'
      MODAL_TITLE: 'Edit Table'
      TICKER:
        LABEL: 'Source'
      EMPTY_TABLE: 'No data'
      # Empty table message that only show in edit mode
      EMPTY_TABLE_EDIT: 'Missing ticker parameter. Press edit button to edit this table.'
      COL:
        # Translated by key/value (they are the same) of ArticleTableColumn
        RANK: 'Rank'
        RANK_DELTA: 'Change in ranking'
        RATE_DELTA: 'Change in forecast'
        TICKER: 'Ticker'
        NAME: 'Security Name'
        RATING: 'Forecast'
        POS_DRIVERS: 'Positive Drivers'
        NEG_DRIVERS: 'Negative Drivers'
      COL_EXTRA:
        # Additional columns that is not in the enum but in table rendering code
        POS_DRIVER_KEY: 'Positive Drivers'
        POS_DRIVER_VAL: 'Values'
        NEG_DRIVER_KEY: 'Negative Drivers'
        NEG_DRIVER_VAL: 'Values'
  ARTICLE_ADMIN_PAGE:
    CONTENT_MANAGEMENT: 'Content Management'
    ABOUT_PAGE_MANAGEMENT: 'About Page Management'

ADMIN:
  SHOWING_NUM_ITEMS: 'Showing %{num} items'
  SELECTED_NUM_ITEMS_OVER_TOTAL_ITEM: 'Selected %{selected} / %{total} items'
  SHOW_SOFT_DELETED_ITEMS: 'Show Soft-Deleted Items'
  API_KEYS:
    CREATE_API_KEY: 'Create API Key'
    EDIT_API_KEY: 'Edit API Key'
    REVIEW_API_KEY: 'Review API Key'
    USER_EMAIL: 'User Email'
    API_KEY: 'API Key'
    API_SECRET: 'API Secret'
    API_SCOPE: 'API Scope'
    NEW_API_SECRET: 'New API Secret'
    USER_EMAIL_PLACEHOLDER: 'Enter User Email'
    API_SECRET_PLACEHOLDER: 'Enter API Secret'
    API_SCOPES:
      DATA: 'Data'
      STATIC: 'Static Info'
      DATAFEED: 'Price History'
      MYLAB_CONTROLLER: 'My Lab API (Private Track)'
      SUBSCRIPTION: 'Subscription API (Morningstar)'
      ANALYTICS_LEGACY: 'Analytics (Legacy)'
      CALCUL_PORTFOLIO: 'Portfolio Backtest'
      CALCUL_REGRESSION: 'Regression'
      CALCUL_DISPERSION: 'Dispersion'
      CALCUL_HCLUST: 'Hierarchical Clustering'
      CALCUL_MST: 'MST'
      CALCUL_PCA: 'PCA'
      CALCUL_FRONTIER: 'Frontier'
      CALCUL_METRIC: 'Risk Metrics'
      RISK__POSITION_LEGACY: 'Position & Risk (Legacy)'
      RISK_PORTFOLIO: 'Risk API'
      RISK_POSITION: 'Position API (Deprecated)'
      PORTFOLIO_CONSTRUCTION: 'Portfolio Construction'
      LOOKTHROUGH: 'Lookthrough'
      LOOKTHROUGH_RISK: 'Lookthrough Risk'
      LOOKTHROUGH_POSITION: 'Lookthrough Position'
      LOOKTHROUGH_PERF: 'Lookthrough Performance'
      ADMIN_LOG: 'Admin Log'
      FACTOR_DECOMPOSITION_CONTROLLER: 'Factor Decomposition'
      PERFORMANCE_EXPLANATION: 'Performance Explanation'
      TRADES_CONTROLLER: 'Trades (Swap Details)'
  RISK:
    RPP_JOB_STATUS: 'RPP Job Status'
    RISK_EXCEL_JOB_STATUS: 'Risk Excel Job Status'
    DAILY_BATCH: 'Daily Batch'
    POC: 'POC'
    FROM: 'From'
    TO: 'to'
    SLUG_SEARCH: 'Search by slug'
  SETTINGS:
    GEN_METRICS: 'Gen Metrics'
  STRATEGIES:
    TRACK_TYPE:
      DATAFEED: 'Datafeed'
      MORNINGSTAR_REQUEST: 'Morningstar Request'
      PRIVATE_TRACK: 'Private Track'
      PROXY: 'Substitution Proxy'
    OFFICIAL_TRACKS_TYPE_NAME:
      BENCHMARK: 'Benchmark'
      FUND: 'Fund & ETF'
      PRIVATE_STRATEGY: 'Strategy (Private)'
      PURE_FACTOR: 'Pure Factor'
      STRATEGY: 'Strategy (Public)'
      THEMATIC: 'Thematic (Private)'
      PUBLIC_THEMATIC: 'Thematic (Public)'
      THEMATIC_BENCHMARK: 'Thematic (Non-bank)'
      MARKET_DATA: 'Market Data (Public)'
      STOCK: 'Stock'
    ACTIONS:
      ADD_STRATEGY: 'Add Strategy'
      MANAGE_TEAM_IDS: 'Manage Team IDs'
      INCLUDE: 'Include'
      EXCLUDE: 'Exclude'
      INVALID_TEAM_IDS: 'You must enter at least one valid team ID'
    LONG_NAME: 'Long Name'
    SHORT_NAME: 'Short Name'
    VOLATILITY_TARGET_PERCENT: 'Volatility Target in (%)'
    NET_RETURN: 'Net Return'
    DESCRIPTION: 'Description'
    DISCLAIMER: 'Disclaimer'
    RESET: 'Reset'
    CLONE: 'Clone'
    EXPORT_TRACK_C_S_V: 'Export Track CSV'
    SHOW_TRACKS: 'Show Tracks'
    COMING_FROM_BACKEND_DATAFEED: 'Coming from backend datafeed'
    LAST_VALUE_DATE: 'Last value date'
    DELAY: 'Delay'
    INDEX_TYPE: 'Index Type'
    DELAY_DAYS: '%{delay} Day | %{delay} Days'
    YES: 'Yes'
    NO: 'No'
    SOFT_DELETED: 'Soft-Deleted'
    ACTIVE: 'Active'
    UNKNOWN_VALUE: 'Unspecified / Unknown'
  STRATEGIES_EDIT:
    TEAM_TABLE:
      TEAM: 'Team'
      TRACK_ACCESS: 'Track Access'
    SHOW_TRACKS: 'Show Tracks'
    SOFT_DELETE: 'Soft Delete'
    CLONE: 'Clone'
    CANCEL_CHANGES: 'Cancel Changes'
    FACTSHEET: 'Factsheet'
    REPLACED_TIME_SERIES_WARNING_TITLE: 'Notice'
    REPLACED_TIME_SERIES_WARNING_CONTENT: 'This time series has been replaced by {0}.'
    RECOVER_REPLACED_TIME_SERIES_ERROR_TITLE: 'Cannot restore item'
    RECOVER_REPLACED_TIME_SERIES_ERROR_CONTENT: 'This item has been replaced by %{code}. Please contact the data team if this item needs to be restored.'
    RECOVER_REPLACED_TIME_SERIES_ERROR_CONTENT_WHITELABEL: 'This item has been replaced by %{code}. Please contact Client Services if this item needs to be restored.'
    STATIC_INFORMATION: 'Static Information'
    STATIC_INFORMATION_SUBTITLE: 'Set and edit the attributes of the strategy'
    NOTE: 'Note'
    CREATE_STRATEGY_CODE_WARNING: 'Note: The strategy code cannot be changed once saved.'
    INVALID_PROVIDER_WARNING: 'You must select an Index Sponsor as provider for strategy.'
    VOL_TARGET_EXAMPLE: 'Example: VT 5%'
    DATE_DISCLAIMER: 'Dates are formatted according to your system configuration'
    TRANSLATE: 'Translate'
    MOCK_STATIC_DATA: 'Mock Static data'
    IDENTIFIERS: 'Identifiers'
    TRACKS: 'Tracks'
    TRACK_SOURCES: 'Track Sources'
    CONTRIBUTION_STATUS: 'Contribution Status'
    LINKED_INSTRUMENTS: 'Linked Instruments'
    ACCESSIBILITY: 'Accessibility'
    ACCESSIBILITY_SUBTITLE: 'Overview of the teams who have access to this strategy'
    BUTTONS:
      CLEAR: 'Clear Form'
  CLIENT_REQUEST:
    REQUEST_STATUS: 'Request Status'
    CONSTITUENT_VALIDATION_STATUS: 'Constituent/Validation Status'
    REQUEST_DETAILS: 'Request Details'
    VALIDATION_STATUS: 'Validation Status'

    TOTAL_NUM_STRATEGIES: 'Total %{num} strategies'
    REQUEST: 'Request'
    REQUEST_STRATEGY: 'Request Strategy'
    INVALID_STRATEGY_TICKER_INPUT_FEEDBACK: 'Strategy Tickers to be requested must be unique'

    VIEW_REQUEST_DETAILS: 'View Request Details'
    VIEW_VALIDATION_STATUS: 'View Validation Status'

    CREATE_CLIENT_REQUEST: 'Create Client Request'
    EDIT_CLIENT_REQUEST: 'Edit Client Request'

    CLIENT: 'Client'
    PROVIDER: 'Provider'
    TEAM: 'Team'
    REGION: 'Region'
    JIRA_TICKET: 'Jira Ticket'
    PORTFOLIO_NAME: 'Portfolio Name'
    POSITION_DATE: 'Position Date'
    REQUEST_TYPE: 'Request Type'
    REPORTER: 'Reporter'
    ASSIGNEE: 'Assignee'
    START_DATE: 'Start Date'
    DUE_DATE: 'Due Date'
    CREATED: 'Created'
    UPDATED: 'Updated'
    PRIORITY: 'Priority'
    STATUS: 'Status'
    STRATEGY_TICKERS: 'Strategy Tickers'
    DESCRIPTION: 'Description'
    EDIT_STRATEGY_LIST: 'Edit Strategy List'
    ADD_MASTER_STRATEGY_TICKERS: 'Add Master Strategy Tickers'
    REMOVE_MASTER_STRATEGY_TICKERS: 'Remove Master Strategy Tickers'
    OVERVIEW: 'Overview'
    ACCESSIBILITY: 'Accessibility'
    TEAM_ACCESS_OVERVIEW: 'Overview of the team who has access to this track'
    SAMPLE_PORTFOLIO_SLUG: 'Sample Portfolio Slug'

    UPDATE_STRATEGY_REQUEST_LIST: 'Update the strategy request list to reflect the latest requirement. Please only input the master strategy tickers.'
    SET_EDIT_PORTFOLIO_ATTRIBUTES: 'Set and edit the attributes of the Portfolio'

    REGIONS:
      APAC: 'APAC'
      EUROPE: 'EU'
      US: 'US'

    PRIORITIES:
      LOWEST: 'Lowest'
      LOW: 'Low'
      MEDIUM: 'Medium'
      HIGH: 'High'
      HIGHEST: 'Highest'

    BATCH_EDIT_PROVIDER_NAME: 'Edit Provider Name in Batch'
    BATCH_EDIT_RELEASE_STATUS: 'Edit Release Status in Batch'
    BATCH_EDIT_CONSTITUENT_STATUS: 'Edit Constituent Status in Batch'
    BATCH_EDIT_VALIDATION_STATUS: 'Edit Validation Status in Batch'
    PROVIDER_NAME: 'Provider Name'
    RELEASE_STATUS: 'Release Status'
    CONSTITUENT_STATUS: 'Constituent Status'
    LAST_UPDATED_AT: 'Last Updated at:'
    UPDATED_BY: 'Updated by:'
  BASKET_LIST:
    REQUEST_LIST:
      TITLE: 'Request List'
      LIST_TYPE:
        PENDING: 'Pending'
        ACKNOWLEDGED: 'Acknowledged'
      SEARCH_BASKET: 'Search Basket'
      ACTION:
        APPROVE: 'Approve'
        REJECT: 'Reject'
        ACKNOWLEDGE: 'Acknowledge'
        SEE_DETAILS: 'See Details'
        SEND_COMMENTS: 'Send Comments'
      ACTION_STATUS:
        APPROVED: 'Approved'
        REJECTED: 'Rejected'
        ACKNOWLEDGED: 'Acknowledged'
        PENDING: 'Pending'
      BUSINESS_EVENT:
        CREATION: 'Creation'
        REBALANCE: 'Rebalance'
      STATUS:
        LIVE: 'Live'
        DRAFT: 'Draft'

  TEAM:
    CREATE_TEAM: 'Create Team'
    CREATE_USER: 'Create User'
    ENTER_NAME: 'Enter Name'
    TEAM_TYPE: 'Team Type'
    ENTER_USER_EMAIL: 'Enter user email'
    TEAM_SEARCH: 'Search by team id/name'
    ARTICLE_SEARCH: 'Search by article title/type'
    USER_SEARCH: 'Search by user email'
    SHOWING_NUM_USERS: 'Showing %{num} users'
    SHOWING_NUM_VARIANTS: 'Showing %{num} variants'
    SHOW_BLOCKED_SOFT_DELETED_USERS: 'Show Blocked/Soft-Deleted Users'
    HIDE_BLOCKED_SOFT_DELETED_USERS: 'Hide Blocked/Soft-Deleted Users'
  PROVIDER_TYPES:
    PLATFORM: 'Platform'
    INDEX_SPONSOR: 'Index Sponsor'
    PROVIDER_MORNINGSTAR: 'Provider (Morningstar)'
    PROVIDER_MSCI: 'Provider (MSCI)'
    PROVIDER_REFINITIV: 'Provider (Refinitiv)'
    PROVIDER_FACTSET: 'Provider (FactSet)'
    CLIENT: 'Client'
  SIDEBAR_TITLE:
    ADMIN_CONSOLE: 'Admin Console'
    CLIENT_ADMIN_CONSOLE: 'Client Admin Console'
  NAV_ITEM:
    ACCOUNT_MANAGEMENT: 'Account Management'
    TRACK_MANAGEMENT: 'Track Management'
    TEAM_MANAGEMENT: 'Teams Management'
    RISK_MANAGEMENT: 'Risk Management'
    API_KEYS: 'API Keys'
    SETTINGS: 'Settings'
    PLATFORM_HEALTH: 'Platform Health'
    CONTENT_MANAGEMENT: 'Content Management'
    PERFORMANCE_CONTRIBUTION_CONFIG: 'Performance Contribution'
  PLACEHOLDERS:
    ENTER_STRATEGY_CODES: 'Enter Strategy Codes (e.g. CICXCV04 CICXCV05)'
    ENTER_STRATEGY_NAME__CODE: 'Enter Strategy Name/Code'
  PDF_MANAGEMENT:
    EXPORT_ALL_PDF: 'Export All PDFs'
    NOT_AVAILABLE: 'Not Available'
  PORTFOLIO_MANAGEMENT: 'Portfolio Management'
  PORTFOLIO_MANAGEMENT_SEARCH: 'Enter Portfolio Name / Slug'

SETTINGS:
  USER_SETTINGS: 'User Settings'
  ALERT_NON_AUTH0_USER: 'Your account credential and profile details are provided by your IT department. If you want to update those details, contact them or your admin.'
  NAV_ITEM:
    UPDATE_PROFILE: 'Update Profile'
    PASSWORD_RESET: 'Reset Login Password'
    TEAM_MANAGEMENT: 'Team Management'
    DISPLAY_PREFERENCES: 'Display Preferences'
    TEAM_DEFAULT_PARAMETERS: 'Team Default Parameters'
  UPDATE_PROFILE: 'Update Profile'
  UPDATE: 'Update'
  CANCEL_CHANGES: 'Cancel Changes'
  UPDATE_PROFILE_FORM:
    FIRST_NAME: 'First Name'
    EMAIL: 'Email'
    COMPANY: 'Company'
    POSITION: 'Position'
    LAST_NAME: 'Last Name'
    PHONE: 'Phone'
    COUNTRY: 'Country'
    SECTOR: 'Sector'
  TEAM_MANAGEMENT:
    TITLE: 'Team Management (%{currentActiveAccounts}/%{maxNumAccounts})'
    TABLE_HEADERS:
      NAME: 'Name'
      EMAIL: 'Email'
      ACCOUNT_STATUS: 'Account Status'
      API: 'API'
      LAST_LOGIN: 'Last Login'
  API_MANAGEMENT:
    TITLE: 'API MANAGEMENT'
    PLEASE_CONTACT: 'Please contact'
    QUESTIONS: 'if you have any questions regarding your API access'
    API_DOC: 'API Doc'
    EMAIL_US: 'Email us'
    USAGE_LIMIT: 'Usage (Limit)'
    API_KEY: 'API Key'
    EXPIRY_DATE: 'Expiry Date'
    API_SECRET: 'API Secret'
    STATUS: 'Status'
  PASSWORD:
    PASSWORD_POLICY: 'Password Policy'
    NEW_PASSWORD: 'New Password'
    CONFIRM_PASSWORD: 'Confirm Password'
    PASSWORD_NOT_MATCH: 'Password does not match'
    RESET_PASSWORD: 'Reset Password'
    DONE: 'Changed password'
    FAILED: 'Failed to change password'
    RULES:
      MINLEN: 'At least 10 characters in length'
      LOWCASE: 'Lower case characters (a-z)'
      UPCASE: 'Upper case characters (A-Z)'
      NUMERIC: 'Numeric characters (0-9)'
      SPECIAL: "Special characters (!{'@'}#$%^*)"
      CONSECUTIVE: 'No more than 2 identical characters in a row'
  DISPLAY_PREFERENCES:
    SHORT_NAME: 'Short Name'
    LONG_NAME: 'Long Name'
    CODE: 'Code'
  STRATEGY_DISPLAY_NAME: 'Strategy Display Name'
  LANGUAGE: 'Language'
  UPDATE_LANGUAGE:
    SUCCESS: 'Successfully updated language'
    FAILED: 'Failed to update language'
  LIVE_EXAMPLE: 'Live Example'
  EXAMPLES:
    PORTFOLIO_TREE: 'Portfolio Tree'
    TOOLTIP: 'Tooltip'
    GRAPH_TOOLTIP: 'Graph Tooltip'
    TABLE: 'Table'
  UPDATE_DISPLAY_PREFERENCES:
    SUCCESS: 'Successfully updated display preferences'
    FAILED: 'Failed to update display preferences'
  TEAM_DEFAULT_PARAMETERS:
    ROLLING_PARAMETERS: 'Rolling Parameters'
    ROLLING_METHOD: 'Rolling Method'
    WINDOW: 'Window'
    DECAY_VALUE: 'Decay Value'
    ROLLING_METHOD_OPTIONS:
      FIXED_WINDOW: 'Simple Moving Average'
      EXPONENTIAL_WEIGHTING: 'Exponential Moving Average'
    DECAY_VALUE_INVALID_FEEDBACK: 'Decay value must be between 0 and 1 and have up to 2 decimal places'

LOGIN:
  WELCOME_TO: 'Welcome to'
  WELCOME: 'Welcome'
  PREMIALAB: 'Premialab'
  CONTINUE: 'Continue'
  ENTER_YOUR_PASSWORD: 'Enter your password'
  EMAIL_ADDRESS: 'Email Address'
  PASSWORD: 'Password'
  FORGOT_PASSWORD_?: 'Forgot Password?'

PURE_FACTOR_MONTHLY_REPORT:
  PURE_FACTOR_MONTHLY_REPORT: 'Pure Factor Monthly Report'
  GLOBAL_FACTORS_PERFORMANCE: 'Global Factors Performance'
  TABLE_HEADER_GLOBAL: 'Global'
  ALL_REPORTS: 'All Reports'

  # Note: These are names of Pure Factors that we show on the Research page. They contain additional factors
  # (Backwardation and Trend) that we do not support as strategy attributes, so they shouldn't be merged with
  # GLOBAL.FACTOR_CONSTANTS. There is also no guarantee that the name of the Pure Factor and the strategy attribute
  # will have the exact same translation in all languages due to language differences.
  #
  # Todo: keep in sync with GLOBAL.FACTOR_CONSTANTS
  PURE_FACTOR_NAMES:
    CARRY: 'Carry'
    BACKWARDATION: 'Backwardation'
    VOLATILITY: 'Volatility'
    SIZE: 'Size'
    VALUE: 'Value'
    LOW_VOL: 'Low Vol'
    TREND: 'Trend'
    MOMENTUM: 'Momentum'
    QUALITY: 'Quality'

SWAP_DETAILS:
  NEW_SWAP_TRADE: 'New Swap Trade'
  NEW_PRODUCT_TRADE: 'New Product Trade'
  NAME: 'Name'
  SWAP: 'Swap'
  PERFORMANCE_LEG: 'Performance Leg'
  THIRD_PARTIES: 'Third Parties'
  FUNDING_LEG: 'Funding Leg'
  PRODUCT: 'Product'
  FINANCIALS: 'Financials'
  TYPE:
    INDEX_SWAP: 'Index Swap'
    MASTER_INDEX_SWAP: 'Master Index Swap'
    EQUITY_BASKET_SWAP: 'Equity Basket Swap'
    EQUITY_SWAP: 'Equity Swap'
  FREQUENCY:
    WEEKLY: 'Weekly'
    MONTHLY: 'Monthly'
    YEARLY: 'Yearly'
  PAY_RULE:
    MODIFIED_FOLLOWING: 'Modified Following'
    FOLLOWING: 'Following'
    PRECEDING: 'Preceding'
    EOM: 'End of Month'
  STATUS:
    DRAFT: 'Draft'
    LIVE: 'Live'
    REBALANCED: 'Rebalanced'
    TERMINATED: 'Terminated'
    DELETED: 'Deleted'
  FLOAT_RATE:
    NO_FLOAT_RATE: 'No Float Rate'
  DIRECTION:
    LONG: 'Long'
    SHORT: 'Short'
  VIEWS:
    TABLE: 'Table'
    TIMELINE: 'Timeline'
  EDIT_ENTITIES: 'Edit Entities'
  TABLE:
    COL:
      PORTFOLIO: 'Portfolio'
      TICKER: 'Ticker'
      INDEX_NAME: 'Index Name'
      PROVIDER: 'Provider'
      TRADE_DATE: 'Trade Date'
      STATUS: 'Status'
  INSPECT:
    PRODUCT: 'Product'
    FX: 'FX'
    NET_AMOUNT: 'Net Amount'
    NET_AMOUNT_CURRENCY: 'Net Amount Currency'
    DETAILED_SCHEDULE: 'Detailed Schedule'
    CREATE_SCHEDULE: 'Create Schedule'
    EDIT_SCHEDULE: 'Edit Schedule'
    LEG: 'Leg'
    PAYMENT_DATE: 'Payment Date'
    FEES_BPS: 'Fees (bps)'
    FEES: 'Fees'
    FIXING: 'Fixing'
    TRADE_ID: 'Trade ID'
    DIRECTION: 'Direction'
    FUND_NAME: 'Fund Name'
    COUNTERPARTY: 'Counterparty'
    CUSTODIAN: 'Custodian'
    SWAP_TYPE: 'Swap Type'
    STATUS: 'Status'
    TRADE_DATE: 'Trade Date'
    CURRENCY: 'Currency'
    INDEX: 'Index'
    QUANTITY: 'Quantity'
    PRICE: 'Price'
    FX_FIXING: 'FX Fixing'
    START_DATE: 'Start Date'
    END_DATE: 'End Date'
    PAY_FREQUENCY: 'Pay Frequency'
    PAYMENT_LAG: 'Payment Lag'
    PAY_RULE: 'Pay Rule'
    REBALANCING_FEES: 'Rebalancing Fees'
    FLOAT_RATE: 'Float Rate'
    SPREAD: 'Spread'
    FIXING_FREQUENCY: 'Fixing Frequency'
    DAY_COUNT: 'Day Count'
    NOTIONAL: 'Notional'
    CASH_FLOW: 'Cash Flow'
  SAVE_DRAFT: 'Save Draft'
  VALIDATE: 'Validate'
  ADD_VALUES: 'Add Values'
  MANAGE_VALUES: 'Manage Values'
  REMOVE_VALUE: 'Remove Value'

DROP_ZONE:
  DROP_AREA_TITLE: 'Drop file here or click to upload'
  DROP_AREA_SUBTITLE1: 'Max 5MB Per File'
  DROP_AREA_SUBTITLE2: 'Supported file types: .xlsx only'

  CHOOSE_FILE_TYPE: 'Choose File Type'
  FILE_TYPES:
    PORTFOLIO: 'Portfolio Snapshot'
    PORTFOLIO_BACKTEST: 'Custom Weighting'
    PRIVATE_TRACK: 'Private Track'

  PORTFOLIO_FILE_HEADERS:
    FILTER_PORTFOLIO: 'Filter portfolios'
    PORTFOLIO_NAME: 'Portfolio Name'
    POSITION_DATE: 'Position Date'
    LAST_UPDATED: 'Last Updated'
    FILE_NAME: 'File Name'

  PRIVATE_TRACK_FILE_HEADERS:
    TRACK_NAME: 'Track Name'
    UPLOADED_AT: 'Uploaded At'
    LAST_UPDATED: 'Last Updated'

  PRIVATE_TRACK_UPLOAD:
    TAB_NAMES:
      STEP_1_SELECT_WORKSHEET: 'Select Worksheet to Map'
      STEP_2_SELECT_HEADER_ROW: 'Select Strategy Header Row'
      STEP_3_SELECT_DATA_ROW: 'Select First Data Row'
      STEP_4_SELECT_DATE_COLUMN: 'Select Date Column(s)'
      STEP_5_SELECT_DATE_FORMAT: 'Choose Date Format'
      STEP_6_SELECT_TRACK: 'Select Associated Track(s)'
      STEP_7_VALIDATE_UPLOAD: 'Check and Validate Data to Map'
    BUTTONS:
      NEXT: 'Next'
      BACK: 'Back'
    DATE_FORMAT_TAB:
      DATE_NOT_DETECTED: 'Date format could not be detected. Please specify the date format used.'
      STANDARD_FORMAT: 'Standard Format'
      IGNORE_DATE_FORMAT: 'Ignore Date Format'
    VALUE_TYPE:
      PRICE: 'Price'
      RETURN: 'Return'
    VALIDATE_TAB:
      NAME_EMPTY: 'Track Name must not be empty'
      NAME_DUPLICATED: 'Track Name must be unique'

  TOASTS:
    FILE_NOT_RECOGNIZED: 'Warning: the only accepted filetype is .xlsx'
    FILE_TOO_LARGE: 'Warning: uploaded file(s) too large - max 5 MB per file'
    UPLOAD_FILE_SUCCESS: 'File(s) uploaded successfully'
    UPLOAD_FILE_FAILURE: 'Error occurred in uploading the file(s)'

  FETCH_SHEET_NAME_FAILURE: 'Error occurred in retrieving the sheet names'
  FETCH_FILE_DETAIL_FAILURE: 'Error occurred in retrieving the file detail'

  UPLOAD_TRACK_SUCCESS: 'Track(s) uploaded successfully'
  UPLOAD_TRACK_FAILURE: 'Error occurred in uploading the track(s)'

  EXPORT_TRACK_SUCCESS: 'Track(s) exported successfully'
  EXPORT_TRACK_FAILURE: 'Error occurred in exporting as track(s)'

  DELETE_TRACK_SUCCESS: 'Track(s) deleted successfully'
  DELETE_TRACK_FAILURE: 'Error occurred in deleting the track(s)'

  EDIT_BOX_TITLE: 'Warning'
  EDIT_BOX_MESSAGE: 'Please select the private track(s) to edit'
  EDIT_TRACK_SUCCESS: 'Track(s) attributes updated successfully'
  EDIT_TRACK_FAILURE: 'Error occurred in updating the track(s) attributes'

  PARSED_SNIPPET_FAILURE: 'Error occurred in retrieving the parsing details'

  ABORT_PARSING_MESSAGE: 'You are about to leave the private track upload'
  ABORT_PARSING_SUCCESS: 'Track parsing process aborted successfully'
  ABORT_PARSING_FAILURE: 'Error occurred in aborting the track parsing process'

  EDIT_TRACK:
    TRACK_NAME_REQUIRED: 'Track name is required'
    TRACK_NAME_NOT_UNIQUE: 'Track name must be unique'
    ISIN_INVALID: 'ISIN must be a valid identifier or left empty'
    MORNINGSTAR_ID_INVALID: 'Morningstar ID must be a valid identifier or left empty'
    MANAGEMENT_FEE_INVALID: 'Management fee must be a valid number or empty'

MORNINGSTAR:
  TABLE_HEADERS:
    ISIN: 'ISIN'
    NAME: 'Name'
    CODE: 'Morningstar Id'
    ASSET_CLASS: 'Asset Class'
    REGION: 'Region'
    CURRENCY: 'Currency'
    CREATED_AT: 'Date Requested'
    UPDATED_AT: 'Last Updated'
    NUMBER_OF_TRACKS: 'ISIN Variants'
    TICKER: 'Ticker'
    EXCHANGE: 'Exchange'
    # purposefully left blank
    ADD_TO_PORTFOLIO: ''
  TABLE_CELLS:
    NUM_FUND: '%{num} Fund | %{num} Funds'
  REQUEST_IDENTIFIER:
    NUM_REMAINING: '%{num} Remaining'
    REQUEST_NEW: 'Request %{num} New'
    EXCEEDED_SOFT_LIMIT: 'Your request bypasses the contractual monthly allocation. As a courtesy, Premialab offers you a temporary buffer to allow you time to contact your account manager.'
    EXCEEDED_HARD_LIMIT: 'You have reached 125% of your monthly allocation limit. Please contact your account manager to add allocation.'

# Note: This is keyed by **value** (not the key) inside UserStatus enum
USER_STATUS:
  TOOLTIP:
    BLOCKED: 'Account is blocked'
    APPROVED: 'Account is activated'
    PENDING: 'No platform Access'
    VERIFYINGEMAIL: 'Awaiting account activation'
    UNKNOWN: 'Unknown user status?'
  STATUS:
    BLOCKED: 'Blocked'
    APPROVED: 'Approved'
    PENDING: 'Pending'
    VERIFYINGEMAIL: 'Approval sent'
    UNKNOWN: 'Unknown'

COLUMN_SELECTOR:
  AVAILABLE_COLS: 'Available Columns'
  SELECTED_COLS: 'Selected Columns'
  DRAG_DROP_COL_ORDER: 'Drag and drop to adjust order'
  MAX_SELECTED_TOAST: 'You have reached the maximum number of columns allowed'

FORM:
  REQUIRED_FIELD: 'This field is required'
  UNIQUE_NAME: 'Please select a unique private track name'
  REQUIRED_ALPHANUMERIC_FIELD: 'This field is required and must be alphanumeric'
  REQUIRED_ALPHANUMERIC_UPPERCASE_MIN_CHAR_FIELD: 'This field is required and must be alphanumeric and uppercase and minimum of 4 characters'
  REQUIRED_DATE_FORMAT_FIELD: 'This field is required and must be in YYYY-MM-DD format'
  DATE_FORMAT_FIELD: 'Format must be YYYY-MM-DD'
  DATE_IN_RANGE_FIELD: 'The specified date must be within the required date range'
  DATE_WEEKDAY_FIELD: 'The specified date must be a weekday'
  UNIQUE_VALUE: 'Please enter a unique value for this field'
  VALUE_IN_RANGE: 'This value must be between {min} and {max}'
  MAX_DECIMALS: 'This value may only have a maximum of {maxDecimals} decimal places'
  CLEAR: 'Clear'
  SELECT: 'Select...'
  CORRECT_ERRORS: 'Please correct the errors indicated on the form'

APPLICATION_NAVBAR:
  DATA: 'Data'
  ANALYTICS: 'Analytics'
  MY_LAB: 'My Lab'
  NEWS: 'News'
  RESOURCES: 'Resources'
  ADMIN: 'Admin'
  BACK_TO_MY_ACCOUNT: 'Back to My Account'
  DISABLE_READ_ONLY: 'Disable Read-Only Permissions'
  ENABLE_READ_ONLY: 'Enable Read-Only Permissions'
  TOGGLE_STRATEGY_MASK: 'Toggle Strategy Mask'
  TOGGLE_APPLY_USER_DEFAULTS: 'Toggle Applying User Defaults'
  ADMIN_CONSOLE: 'Admin Console'
  ARTICLES_PAGE: 'Articles Page'
  HELP: 'Help'
  TOOLTIPS: 'Tooltips'
  TOUR_GUIDE: 'Tour Guide'
  EMAIL_US: 'Email Us'
  SETTINGS: 'Settings'
  LOGOUT: 'Logout'

APPLICATION_SIDEBAR:
  BASKET_LIST: 'Basket List'
  ANALYTICS: 'Analytics'
  PORTFOLIO_CONSTRUCTION: 'Portfolio Construction'
  EQUITY_BASKET: 'Equity Basket'
  CLUSTERING_ANALYSIS: 'Clustering Analysis'
  FACTOR_DECOMPOSITION: 'Factor Decomposition'
  CONSTITUENT_RISK: 'Constituent Risk'
  FACTSHEET: 'Factsheet'
  PERFORMANCE_CONTRIBUTION: 'Performance Contribution'
  UNIVERSE: 'Universe'
  INSIGHTS: 'Insights'
  HOME: 'Home'
  DROPZONE: 'Dropzone'
  ADMIN_CONSOLE: 'Admin Console'
  MY_LAB: 'My Lab'
  GLOSSARY: 'Glossary'
  RESOURCES: 'Resources'
  USER_SETTINGS: 'Settings'
  DATA: 'Data'
  SETTINGS: 'Settings'
  POSITION: 'Position'

RESOURCES:
  FILE_DOWNLOADS:
    PLBPY_HEADER: 'Download PLBPY'
    LATEST: 'Latest'
    TABLE_LABEL:
      FILE: 'File'
      VERSION: 'Version'
      SIZE: 'Size'
      DOWNLOAD: 'Download'
  RISK_REPORT_DOWNLOADS:
    TABLE_LABEL:
      PORTFOLIO_NAME: 'Portfolio Name'
      ANALYSIS_DATE: 'Analysis Date'
      LAST_MODIFIED: 'Last Modified'
      DOWNLOAD: 'Download'

APP_FOOTER:
  TERMS_CONDITIONS: 'Terms and conditions'
  PRIVACY_POLICY: 'Privacy policy'
  FAUX_WHITELABEL:
    POWERED_BY: 'Powered by {0}'

ACTIVE_RETURN:
  TITLE: 'Active Return'
  TRACK: 'Active Return Track'
  DATA_TYPE:
    PORTFOLIO: 'Portfolio'
    TRACK: 'Track'
    BENCHMARK: 'Benchmark'

UNAUTHORIZED_PAGE:
  PERMISSION: 'In order to use this function, please {0} to upgrade your account.'
  CONTACT_US: 'contact us'
  BLOCKED: 'Your account currently does not have access to the platform. Please reach out to your sales manager or {0} for further assistance.'
  CLIENT_SERVICE: 'client services'
  SERVICE_UNAVAILABLE: 'Service unavailable. Please try again later. If the issue persists please reach out to {0} for assistance.'

COMPLIANCE_RULES:
  COMPLIANCE_RULES: 'Compliance Rules'
  COMPLIANCE: 'Compliance'
  LIST:
    DEFAULT_RULES: 'Default Rules'
    CUSTOM_RULES: 'Custom Rules'
  TAB:
    STOCK_RESTRICTION: 'Stock Restriction'
    ALLOCATION_LIMITATION: 'Allocation Limitation'
  DEFINE_RULE: 'Define Compliance Rule -'
  OPTIONS:
    TICKER: 'Ticker'
    COUNTRY: 'Country'
    SECTOR: 'Sector'
    EXCHANGE: 'Exchange'
    CURRENCY: 'Currency'
    THEME: 'Theme'
    LIQUIDITY_RESTRICTION: 'Liquidity Restriction'
    ESG: 'ESG'
