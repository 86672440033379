<template>
  <div>
    <b-navbar
      id="app-navbar"
      toggleable="lg"
      type="dark"
      variant="primary"
      fixed="top"
      class="navbar-style text-uppercase"
      :style="{
        'min-height': '75px',
      }"
    >
      <!-- The screen size component sets the Screen Size in settings module which plays a role in the responsiveness of a few pages
     we will eventually need to move away from responsiveness via the settings module, and use the composable instead
    -->
      <ScreenSizeComponent class="d-none d-md-block" />
      <b-container class="h-100">
        <b-navbar-brand
          style="height: 42px; width: 105px"
          @click="onClickLogo"
        >
          <img
            src="@/assets/hsbc.svg"
            :alt="companyName"
          />
        </b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse">
          <template #default="{ expanded }">
            <icon
              v-if="expanded"
              icon="remove"
            />
            <icon
              v-else
              icon="bars"
            />
          </template>
        </b-navbar-toggle>

        <ApplicationNavbar />
      </b-container>
    </b-navbar>
    <b-modal
      ref="ieWarningModal"
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      body-class="pt-4pt5 px-4pt5"
      footer-class="border-0 pb-4pt5"
    >
      <p>It is recommended that you use Chrome or Firefox for the best user experience.</p>
      <p class="mb-0">
        Please let the team know at
        <a :href="`mailto:${clientServicesEmail}`">{{ clientServicesEmail }}</a>
        if you encounter any problems while using our platform with Internet Explorer.
      </p>
      <template #modal-footer="{ ok }">
        <b-container>
          <b-row>
            <b-col />
            <b-col>
              <div class="w-100 d-flex justify-content-center">
                <b-button
                  variant="info"
                  size="sm"
                  block
                  @click="ok()"
                >
                  OK
                </b-button>
              </div>
            </b-col>
            <b-col />
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>

<script lang="ts">
import Bowser from 'bowser';
import { BModal } from 'bootstrap-vue';
import ApplicationNavbar from './ApplicationNavbar.vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import ScreenSizeComponent from '@/layout/components/navbar/ScreenSizeComponent.vue';
import { useMobileResponsiveUtilities } from '@/composables/useMobileResponsiveUtilities';
import useUser from '@/composables/useUser';
import { RouteName } from '@/constants/RouteName';
import { useRouter } from 'vue-router/composables';
import { useApplicationSidebar } from '@/composables/useApplicationSidebar';

/**
 * Consider combining this component with the ApplicationNavbar component
 * not sure there's need for both
 */
export default defineComponent({
  name: 'ApplicationNavbarShell',
  components: {
    ApplicationNavbar,
    ScreenSizeComponent,
  },
  setup() {
    const ieWarningModal = ref<BModal | null>(null);

    const companyName = computed(() => {
      return import.meta.env.VITE_CLIENT;
    });

    const clientServicesEmail = computed(() => {
      return import.meta.env.VITE_CLIENT_SERVICES_EMAIL;
    });

    const { user } = useUser();

    const defaultSlug = computed(() => {
      return user.value?.defaultSlug;
    });

    const { isSmallScreen } = useMobileResponsiveUtilities();

    const router = useRouter();

    const { toggleApplicationSidebar } = useApplicationSidebar();

    const onClickLogo = () => {
      if (isSmallScreen.value) {
        toggleApplicationSidebar();
        return;
      }
      router.push({ name: RouteName.DATA });
    };

    onMounted(() => {
      const bowser = Bowser.parse(window.navigator.userAgent);
      const usingIe = bowser.browser.name === 'Internet Explorer';

      if (usingIe && ieWarningModal.value) {
        ieWarningModal.value.show();
      }
    });

    return {
      ieWarningModal,
      companyName,
      clientServicesEmail,
      defaultSlug,
      isSmallScreen,
      user,
      onClickLogo,
    };
  },
});
</script>
<style>
.enforce-navbar-brand-width {
  width: 240px;
}
</style>
