import request, { checkBlockPopup } from '@/api-v2/request';
import {
  IAppendPortfolioTreeParams,
  IPortfolioTree,
  IPortfolioTreeDraftParams,
  ISetPortfolioTreeParams,
} from '@/types/IPortfolioTree';
import {
  IPortfolioTreeCorrelationMatrixResponse,
  IPortfolioTreeCorrelationQuery,
  IPortfolioTreeCorrelationResponse,
} from '@/types/IPortfolioTreeCorrelation';
import { IPortfolioTreeExposureQuery } from '@/types/IPortfolioTreeExposureQuery';
import { IPortfolioTreeExposureResponse } from '@/types/IPortfolioTreeExposureResponse';
import { IPortfolioTreeMetricsResponse } from '@/types/IPortfolioTreeMetricsResponse';
import {
  IPortfolioTreeRollingTrackQuery,
  IPortfolioTreeRollingTrackResponse,
} from '@/types/IPortfolioTreeRollingTrack';
import { IPortfolioTreeTracksQuery } from '@/types/IPortfolioTreeTracksQuery';
import { IPortfolioTreeTrackResponse } from '@/types/IPortfolioTreeTracksResponse';
import { IPortfolioTreeWeightsQuery, IPortfolioTreeWeightsResponseItem } from '@/types/IPortfolioTreeWeights';
import { IFrontierLineResponse, IPortfolioTreeFrontierQuery } from '@/types/query/IFrontierLineQuery';
import { IPortfolioTreeSnapshotResponse } from '@/types/IPortfolioTreeSnapshot';
import { IPortfolioTreeBacktestResponse } from '@/types/IPortfolioTreeBacktest';
import { IPortfolioTreeMstQuery } from '@/types/IPortfolioTreeMst';
import { IMstResponse } from '@/types/IMstResponse';
import { IPortfolioTreeComponent, IPortfolioTreeComponentQuery } from '@/types/IPortfolioTreeComponent';
import { IPeriodMetricsQuery } from '@/types/dto/IPeriodMetricsQuery';
import { IPortfolioTreeRiskMetricsResponse } from '@/types/IPortfolioTreeRiskMetricsResponse';
import { IReturnRegressionResponse } from '@/types/IReturnRegressionResponse';
import { ReturnInterval } from '@/constants/ReturnInterval';
import { FileSignedUrlDTO } from '@/types/dto/FileSignedUrlDTO';
import { RouteName } from '@/constants/RouteName';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import { Locales } from '@/constants/Locales';
import { useDefaultPdfOptions } from '@/composables/usePdf';
import { FileService } from '@/api-v2/web/file';
import { ReturnRegressionPortfolioRequestDTO } from '../active-return/types/ReturnRegressionPortfolioRequestDTO';
import { PublishPortfolioDTO } from './types/PublishPortfolioDTO';
import { ExcelExportRequest } from './types/ExcelExportRequest';
import { FactsheetPdfParams } from '@/types/FactsheetPdfParams';
import { IKdeResponseDto } from '@/types/IKdeResponseDto';
import { IPortfolioKdeQuery } from '@/types/IPortfolioKdeQuery';
import { LinkedPortfoliosDependencyQuery } from '@/types/LinkedPortfoliosDependencyQuery';

const defaultPdfOptions = useDefaultPdfOptions();

export const getPortfolioTreeBySlug = (slug: string): Promise<IPortfolioTree> =>
  request({
    url: `/portfolio-trees/${slug}`,
    method: 'get',
  }).then((o): IPortfolioTree => o.data);

/**
 * IMPORTANT NOTE: this ALWAYS returns a *new* portfolio tree draft, NOT the saved draft in the database
 */
export const getPortfolioTreeNewDraft = (
  originalPortfolioSlug: string,
  params: IPortfolioTreeDraftParams,
): Promise<IPortfolioTree> =>
  request({
    url: `/portfolio-trees/${originalPortfolioSlug}/draft`,
    method: 'post',
    params,
  }).then((o): IPortfolioTree => o.data);

/**
 * update existing portfolio
 */
export const setPortfolioTree = (slug: string, payload: ISetPortfolioTreeParams): Promise<IPortfolioTree> =>
  request({
    url: `/portfolio-trees/${slug}`,
    method: 'post',
    data: payload,
  }).then((o): IPortfolioTree => o.data);

/**
 * append portfolio to another portfolio
 */
export const appendPortfolioTree = (slug: string, payload: IAppendPortfolioTreeParams): Promise<IPortfolioTree> =>
  request({
    url: `/portfolio-trees/${slug}/append`,
    method: 'post',
    data: payload,
  }).then((o): IPortfolioTree => o.data);

export const createPortfolioTree = (payload: ISetPortfolioTreeParams): Promise<IPortfolioTree> =>
  request({
    url: `/portfolio-trees`,
    method: 'post',
    data: payload,
  }).then((o): IPortfolioTree => o.data);

export const createDefaultPortfolioTree = (): Promise<IPortfolioTree> =>
  request({
    url: `/portfolio-trees/default`,
    method: 'post',
  }).then((o): IPortfolioTree => o.data);

export const savePortfolioTree = (slug: string): Promise<IPortfolioTree> =>
  request({
    url: `/portfolio-trees/${slug}/save`,
    method: 'post',
  }).then((o): IPortfolioTree => o.data);

/**
 * create a new portfolio from existing portfolio
 */
export const copyPortfolioTree = (slug: string, portfolioRequest: ISetPortfolioTreeParams): Promise<IPortfolioTree> =>
  request({
    url: `/portfolio-trees/${slug}/copy`,
    method: 'post',
    data: portfolioRequest,
  }).then((o) => o.data);

export const deletePortfolioTree = (slug: string): Promise<void> =>
  request({
    url: `/portfolio-trees/${slug}`,
    method: 'delete',
  }).then((o) => o.data);

export const getPortfolioTreeTrack = (
  slug: string,
  query: IPortfolioTreeTracksQuery,
): Promise<IPortfolioTreeTrackResponse[]> =>
  request({
    url: `/portfolio-trees/${slug}/tracks`,
    method: 'get',
    params: query,
  }).then((o): IPortfolioTreeTrackResponse[] => o.data);

/**
 * The main difference between this API and `getRiskMetricsPeriod`
 * is that this query allows you to pass in portfolio tree ids,
 * thus allowing you to get the risk metrics for subportfolios
 *
 * ALSO, the other one is DEPRECATED. So use this one.
 */
export const getPortfolioTreeMetrics = (
  slug: string,
  query: IPortfolioTreeTracksQuery | null,
): Promise<IPortfolioTreeMetricsResponse[]> =>
  request({
    url: `/portfolio-trees/${slug}/metrics`,
    method: 'get',
    params: query,
  }).then((o): IPortfolioTreeMetricsResponse[] => o.data);

export const getPortfolioTreeExposure = (
  slug: string,
  query: IPortfolioTreeExposureQuery,
): Promise<IPortfolioTreeExposureResponse> =>
  request({
    url: `/portfolio-trees/${slug}/exposure`,
    method: 'get',
    params: query,
  }).then((o): IPortfolioTreeExposureResponse => o.data);

export const getPortfolioTreeRollingTrack = ({
  slug,
  query,
}: {
  slug: string;
  query: IPortfolioTreeRollingTrackQuery;
}): Promise<IPortfolioTreeRollingTrackResponse> =>
  request({
    url: `/portfolio-trees/${slug}/rolling-track`,
    method: 'get',
    params: query,
  }).then((o): IPortfolioTreeRollingTrackResponse => o.data);

export const getPortfolioTreeCorrelation = (
  slug: string,
  params: IPortfolioTreeCorrelationQuery,
): Promise<IPortfolioTreeCorrelationResponse> =>
  request({
    url: `/portfolio-trees/${slug}/correlation`,
    method: 'get',
    params,
  }).then((o): IPortfolioTreeCorrelationResponse => o.data);

export const getPortfolioTreeCorrelationMatrix = (param: {
  slug: string;
  query: IPortfolioTreeCorrelationQuery;
}): Promise<IPortfolioTreeCorrelationMatrixResponse> =>
  request({
    url: `/portfolio-trees/${param.slug}/correlation-matrix`,
    method: 'get',
    params: param.query,
  }).then((o): IPortfolioTreeCorrelationMatrixResponse => o.data);

export const getPortfolioTreeWeights = ({
  slug,
  params,
}: {
  slug: string;
  params: IPortfolioTreeWeightsQuery;
}): Promise<IPortfolioTreeWeightsResponseItem> =>
  request({
    url: `/portfolio-trees/${slug}/weight`,
    method: 'get',
    params,
  }).then((o): IPortfolioTreeWeightsResponseItem => o.data);

export const getPortfolioTreeFrontier = (
  {
    slug,
    query,
  }: {
    slug: string;
    query: IPortfolioTreeFrontierQuery;
  },
  signal?: AbortSignal,
): Promise<IFrontierLineResponse> =>
  request({
    url: `/portfolio-trees/${slug}/frontier`,
    method: 'get',
    params: query,
    signal,
  }).then((o): IFrontierLineResponse => o.data);

export const getPortfolioTreeSnapshot = ({ slug }: { slug: string }): Promise<IPortfolioTreeSnapshotResponse[]> =>
  request({
    url: `/portfolio-trees/${slug}/snapshot`,
    method: 'get',
  }).then((o): IPortfolioTreeSnapshotResponse[] => o.data);

export const getPortfolioTreeBacktest = ({ slug }: { slug: string }): Promise<IPortfolioTreeBacktestResponse[]> =>
  request({
    url: `/portfolio-trees/${slug}/backtest`,
    method: 'get',
  }).then((o): IPortfolioTreeBacktestResponse[] => o.data);

export const getPortfolioTreeMst = (param: { slug: string; query: IPortfolioTreeMstQuery }): Promise<IMstResponse> =>
  request({
    url: `/portfolio-trees/${param.slug}/mst`,
    method: 'get',
    params: param.query,
  }).then((o): IMstResponse => o.data);

/**
 * Performance Contribution endpoint
 */
export const getPortfolioConstructionComponent = ({
  slug,
  query,
}: {
  slug: string;
  query: IPortfolioTreeComponentQuery;
}): Promise<IPortfolioTreeComponent> =>
  request({
    url: `/portfolio-trees/${slug}/component`,
    method: 'get',
    params: query,
  }).then((o): IPortfolioTreeComponent => o.data);

/**
 * @deprecated - should use /metrics API instead
 */
export const getRiskMetricsPeriod = (
  slug: string,
  query: IPeriodMetricsQuery,
): Promise<IPortfolioTreeRiskMetricsResponse> =>
  request({
    url: `/portfolio-trees/${slug}/periodMetrics`,
    method: 'get',
    params: query,
  }).then((o): IPortfolioTreeRiskMetricsResponse => o.data);

export const getPortfolioTreeReturnRegression = (
  slug: string,
  payload: ReturnRegressionPortfolioRequestDTO,
): Promise<IReturnRegressionResponse> =>
  request({
    url: `/portfolio-trees/${slug}/regression`,
    method: 'post',
    data: payload,
  }).then((o) => o.data);

export const getPortfolioPdf = async (
  slug: string,
  url: string,
  params: {
    returnInterval: ReturnInterval;
    period: PeriodAbbrevEnum;
    startDate: string;
    endDate: string;
    locale?: Locales;
  },
  indexName: string,
): Promise<void> => {
  const resp = await request({
    url: `/portfolio-trees/${slug}/pdf`,
    method: 'post',
    data: {
      url,
      params,
      options: defaultPdfOptions,
    },
  });
  const dto: FileSignedUrlDTO = resp.data;
  if (!dto.signedURL) return;
  checkBlockPopup();

  await FileService.downloadWithCloudFrontUrl(dto.signedURL, 'pdf', {
    tool: RouteName.PORTFOLIO_FACTSHEET,
    indexName,
    date: params.endDate,
  });
};

export const getPortfolioFrankensteinPdf = async (
  slug: string,
  url: string,
  params: FactsheetPdfParams & {
    locale?: Locales;
  },
  indexName: string,
): Promise<void> => {
  const resp = await request({
    url: `/portfolio-trees/${slug}/pdf/test`,
    method: 'post',
    data: {
      url,
      params,
      options: defaultPdfOptions,
    },
  });
  const dto: FileSignedUrlDTO = resp.data;
  if (!dto.signedURL) return;
  checkBlockPopup();

  await FileService.downloadWithCloudFrontUrl(dto.signedURL, 'pdf', {
    tool: RouteName.TEST_PDF_PORTFOLIO,
    indexName,
    date: params.endDate,
  });
};

export const getPortfolioExcel = async (slug: string, query: ExcelExportRequest, indexName: string): Promise<void> => {
  const resp = await request({
    url: `/portfolio-trees/${slug}/excel`,
    method: 'POST',
    params: query,
  });
  const dto: FileSignedUrlDTO = resp.data;
  if (!dto.signedURL) return;
  checkBlockPopup();

  await FileService.downloadWithCloudFrontUrl(dto.signedURL, 'xlsx', {
    tool: RouteName.PORTFOLIO_FACTSHEET,
    indexName,
  });
};

export const publishPortfoliosToHSBCTeams = (slug: string, payload: PublishPortfolioDTO): Promise<void> =>
  request({
    url: `/portfolio-trees/${slug}/publish`,
    method: 'post',
    data: payload,
  }).then((o) => o.data);

export const getPortfolioTreeKDE = (slug: string, query: IPortfolioKdeQuery): Promise<IKdeResponseDto[]> =>
  request({
    url: `/portfolio-trees/${slug}/kde`,
    method: 'get',
    params: query,
  }).then((o): IKdeResponseDto[] => o.data);

export const getLinkedPortfolioDependency = (slug: string, query: LinkedPortfoliosDependencyQuery): Promise<string[]> =>
  request({
    url: `/portfolio-trees/${slug}/linked-portfolios-dependency`,
    method: 'get',
    params: query,
  }).then((o): string[] => o.data);

export const submitBasket = (slug: string): Promise<IPortfolioTree> =>
  request({
    url: `/portfolio-trees/${slug}/basket/submit`,
    method: 'post',
  }).then((o): IPortfolioTree => o.data);
